import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CrearEncuesta = ({handleRefresh}) => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState('');
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');

  // Fetch clients on component mount
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}admin/get_clients.php`)
      .then(response => {
        setClients(response.data.clients);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleClientChange = event => {
    setClient(event.target.value);
    handleRefresh();
  };

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleUrlChange = event => {
    setUrl(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log(url);

    // Encode form data
    const formData = new FormData();
    formData.append('client', client);
    formData.append('name', name);
    formData.append('url', url);

    // Submit form data to server
    axios.post(`${process.env.REACT_APP_URL}admin/submit_form.php`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        if (response.data.success) {
          // Form submitted successfully
          setMessage(response.data.message);
          setClient('');
          setName('');
          setUrl('');
        } else {
          // Error submitting form
          setMessage(response.data.message);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div className='adminelement'>
      <h1>Crear Encuesta</h1>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="client">Cliente:</label>
          <select id="client" name="client" value={client} onChange={handleClientChange}>
            <option value="">Seleccione un cliente</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>{client.nombre}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="name">Nombre:</label>
          <input id="name" type="text" name="name" value={name} onChange={handleNameChange} />
        </div>
        <div>
          <label htmlFor="url">URL:</label>
          <input id="url" type="text" name="url" value={url} onChange={handleUrlChange} />
        </div>
        <button type="submit">Crear Encuesta</button>
      </form>
    </div>
  );
};

export default CrearEncuesta;