import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Pager from "./Pager";
import AdminEditUser from './AdminEditUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const AdminUsersTable = () => {
  const [data, setData] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [sort, setSort] = useState({ field: 'id', sort: 'desc' });
  const [permiso, setPermiso] = useState('false');
  const [status, setStatus] = useState('false');
  const [cliente, setCliente] = useState('false');
  const [page, setPage] = useState(1);
  const [totales, setTotales] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [editing, setEditing] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };


  useEffect(() => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    axios.get(`${process.env.REACT_APP_API}clientes/getclientes`, {
      cancelToken: source.token
    })
      .then(response => {
        setClientes(response.data.clients);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching user data:', error);
        }
      });
    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  }, []);

  const fetchData = async (page, sort, permiso, status, cliente) => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}usuarios/getusers?pagina=${page}&sort=${sort.field},${sort.sort}&permiso=${permiso}&status=${status}&cliente=${cliente}`,
        {
          cancelToken: source.token,
        }
      );

      setData(response.data.users);
      setTotales(response.data.totalPages);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching user data:', error);
      }
    }

    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  };

  useEffect(() => {
    fetchData(page, sort, permiso, status, cliente);
  }, [page, sort, permiso, status, cliente]);


  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [sort, permiso, status, cliente]);

  const handleEdit = (id) => {
    setEditing(id);
    setShowModal(true);
    console.log(id);
  }

  const saveEdit = () => {
    setShowModal(false);
    fetchData(page, sort, permiso, status, cliente);
    toast.success('Cambios salvados', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }

  const errorDuplicado = () => {
    toast.error('El nombre de usuario ya se encuentra en uso', {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }

  const handleDelete = async (id) => {
    if (!window.confirm('¿Está seguro que desea borrar a este usuario?')) {
      return;
    }
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const response = await axios.delete( // Use DELETE method to delete a resource
        `${process.env.REACT_APP_API}usuarios/deleteuser?id=${id}`, // Use 'id' instead of 'userId'
        {
          cancelToken: source.token,
        }
      );
      // Handle success response if needed
      console.log('User deleted successfully:', response.data.message);
      fetchData(page, sort, permiso, status, cliente);
      toast.info('Usuario borrado correctamente', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error deleting user:', error);
      }
    }

    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  };

  const mapPermisoToLabel = (value) => {
    switch (value) {
      case 0:
        return 'Sin permisos';
      case 1:
        return 'Administrador';
      case 2:
        return 'Editor';
      case 3:
        return 'Usuarios';
      default:
        return 'Todos';
    }
  };


  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div>
            ID
            <button className={`btn ${sort.field === 'id' && sort.sort === 'desc' ? 'active' : ''}`} onClick={() => setSort({ field: 'id', sort: 'desc' })}>
              <ArrowDropUpIcon />
            </button>
            <button className={`btn ${sort.field === 'id' && sort.sort === 'asc' ? 'active' : ''}`} onClick={() => setSort({ field: 'id', sort: 'asc' })}>
              <ArrowDropDownIcon />
            </button>
          </div>
        ),
        accessor: 'id'
      },
      {
        Header: () => (
          <div>
            Nombre
            <button className={`btn ${sort.field === 'nombre' && sort.sort === 'desc' ? 'active' : ''}`} onClick={() => setSort({ field: 'nombre', sort: 'desc' })}>
              <ArrowDropUpIcon />
            </button>
            <button className={`btn ${sort.field === 'nombre' && sort.sort === 'asc' ? 'active' : ''}`} onClick={() => setSort({ field: 'nombre', sort: 'asc' })}>
              <ArrowDropDownIcon />
            </button>
          </div>
        ),
        accessor: 'nombre'
      },
      {
        Header: () => (
          <div>
            Usuario
            <button className={`btn ${sort.field === 'usuario' && sort.sort === 'desc' ? 'active' : ''}`} onClick={() => setSort({ field: 'usuario', sort: 'desc' })}>
              <ArrowDropUpIcon />
            </button>
            <button className={`btn ${sort.field === 'usuario' && sort.sort === 'asc' ? 'active' : ''}`} onClick={() => setSort({ field: 'usuario', sort: 'asc' })}>
              <ArrowDropDownIcon />
            </button>
          </div>
        ),
        accessor: 'usuario'
      },
      {
        Header: () => (
          <div>
            Permiso
            <select value={permiso} onChange={(e) => setPermiso(parseInt(e.target.value))}>
              <option value="false">Todos</option>
              <option value="0">Sin permisos</option>
              <option value="1">Administrador</option>
              <option value="2">Editor</option>
              <option value="3">Usuarios</option>
            </select>
          </div>
        ),
        accessor: 'permiso',
        Cell: ({ cell }) => mapPermisoToLabel(cell.value)
      },
      {
        Header: () => (
          <div>
            Cliente
            <select value={cliente} onChange={(e) => setCliente(parseInt(e.target.value))}>
              <option value="false">Todos</option>
              {Object.values(clientes).map((cliente) => (
                <option key={cliente.id} value={cliente.id}>
                  {cliente.nombre}
                </option>
              ))}
            </select>
          </div>
        ),
        accessor: 'cliente'
      },
      {
        Header: 'Encuesta',
        accessor: 'encuesta'
      },
      {
        Header: () => (
          <div>
            Estatus
            <select value={status} onChange={(e) => setStatus(parseInt(e.target.value))}>
              <option value="false">Todos</option>
              <option value="0">Inactivo</option>
              <option value="1">Activo</option>
            </select>
          </div>
        ),
        accessor: 'status'
      },
      {
        Header: 'Acciones',
        Cell: ({ row }) => (
          <div>
            <button onClick={() => handleEdit(row.original.id)}><EditNoteIcon /></button>
            <button onClick={() => handleDelete(row.original.id)}><DeleteForeverIcon /></button>
          </div>
        )
      }
    ],
    [page, sort, permiso, status, cliente, clientes]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pager page={page} totales={totales} onPageChange={setPage} />
        </div>
      </div>

      <div>
        <ToastContainer />
        <button className="btn btn-primary" onClick={() => handleEdit(false)}>
          Nuevo usuario
        </button>

        <div className={`modal ${showModal ? 'show' : ''} large-modal`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
            <AdminEditUser userId={editing} toggleModal={toggleModal} saveEdit={saveEdit} errorDuplicado={errorDuplicado} />
          </div>
        </div>
        {showModal && <div className="modal-backdrop show" onClick={toggleModal}></div>}
      </div>



    </div>
  );
};

export default AdminUsersTable;