import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class Pantalla11 extends Component {

    render() {
        return (
            <div id="pantalla11" className="contenedorgrafica">
                <Table className="Table Table-borderless Table-striped Table-earning">
                    <Thead>
                        <Tr>
                            <Th>Asesor</Th>
                            <Th>Tienda</Th>
                            <Th>Venta</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr><Td>Yajahira Vianey Fernandez</Td><Td>Swarovski Boutique Galerías Vallart</Td><Td>$86,007</Td></Tr><Tr><Td>Karla Berenice Torres</Td><Td>Swarovski Boutique Universidad</Td><Td>$50,142</Td></Tr><Tr><Td>Tzintli Lopez</Td><Td>Palacio De Hierro Coyoacan</Td><Td>$48,985</Td></Tr><Tr><Td>Marina del Carmen Silva</Td><Td>Swarovski Merida City The Harbour</Td><Td>$17,579</Td></Tr><Tr><Td>Miguel Galicia</Td><Td>Liverpool Atizapan (104)</Td><Td>$114,696</Td></Tr><Tr><Td>Maria Consuelo Wong</Td><Td>Swarovski Boutique Punto Valle MTY</Td><Td>$16,903</Td></Tr><Tr><Td>Aurora Gonzalez</Td><Td>Swarovski Boutique Mty</Td><Td>$82,263</Td></Tr><Tr><Td>Lorena Hernandez</Td><Td>Swarovski Boutique Parques Puebla</Td><Td>$5,449</Td></Tr><Tr><Td>Isis Paola Zepeda</Td><Td>Swarovski Boutique Antara</Td><Td>$148,334</Td></Tr><Tr><Td>Jairo Sosa</Td><Td>Swarovski Boutique Perisur</Td><Td>$145,538</Td></Tr>									</Tbody>
                </Table>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla11);