import React, { Component, Fragment } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

class Pantalla15 extends Component {
    
    render() {
        return (
			<Fragment>
            <div id="re_generales" ><h3 >Por favor compártenos cualquier sugerencia o idea que nos ayude a crear experiencias excepcionales</h3>
	<div className="chart-note-wrap">
		<div className="chart-note mr-0">
			<span className="dot" ></span>
			<span>Bien</span>
		</div>
		<div className="chart-note mr-0">
			<span className="dot"></span>
			<span>Mal</span>
		</div>
		<div className="chart-note mr-0">
			<span className="dot"></span>
			<span>No aplica</span>
		</div>
	</div>
	<div className="texto" ><i className="fas fa-cart-plus"></i> Experiencia de compra <i className="fas fa-user"></i> 207 <i className="fas fa-comment"></i> <button data-area="9" className="bot-area btn btn-sq-xs btn-default"><i className="fas fa-eye-slash"></i></button></div>
<div className="barra" ><div className="color" >117</div>
<div className="color">90</div>
<div className="color">141</div>
<div className="porcentaje">56.52%</div></div>
<div className="texto" ><i className="fas fa-tools"></i> Reparaciones <i className="fas fa-user"></i> 16 <i className="fas fa-comment"></i> <button data-area="10" className="bot-area btn btn-sq-xs btn-default"><i className="fas fa-eye-slash"></i></button></div>
<div className="barra" ><div className="color" >0</div>
<div className="color" >16</div>
<div className="color" >330</div>
<div className="porcentaje">0%</div></div>
<div className="texto" ><i className="fas fa-gem"></i> Calidad de la pieza <i className="fas fa-user"></i> 30 <i className="fas fa-comment"></i> <button data-area="11" className="bot-area btn btn-sq-xs btn-default"><i className="fas fa-eye-slash"></i></button></div>
<div className="barra" ><div className="color" >13</div>
<div className="color" >17</div>
<div className="color" >317</div>
<div className="porcentaje">43.33%</div></div>
<div className="texto" ><i className="fas fa-boxes"></i> Visual Merchandising <i className="fas fa-user"></i> 29 <i className="fas fa-comment"></i> <button data-area="12" className="bot-area btn btn-sq-xs btn-default"><i className="fas fa-eye-slash"></i></button></div>
<div className="barra" ><div className="color" >5</div>
<div className="color" >24</div>
<div className="color" >319</div>
<div className="porcentaje">17.24%</div></div>
<div className="texto" ><i className="fas fa-file-invoice"></i> Operaciones <i className="fas fa-user"></i> 112 <i className="fas fa-comment"></i> <button data-area="13" className="bot-area btn btn-sq-xs btn-default"><i className="fas fa-eye-slash"></i></button></div>
<div className="barra" ><div className="color">29</div>
<div className="color" >83</div>
<div className="color" >229</div>
<div className="porcentaje">25.89%</div></div></div>
</Fragment>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla15);