import React, { Component} from "react";
import { ReactComponent as LogoAnim } from '../img/logo_kitten_para_anim.svg';


class Splash extends Component {
    componentDidMount(){
        var wrapper = document.querySelector('.wrapper svg');
        var splash = document.getElementById('splash');
        function draw() {
            wrapper.classList.add('active');
        }
        function gone() {
            splash.classList.add('gone');
        }
        setTimeout(draw, 500);
        setTimeout(gone, 8000);
    }
    render() {
        return (
            <div id="splash" className="wrapper">
                <LogoAnim />
            </div>
        );
    }
}
export default Splash;