import React, { useState, useEffect } from "react";
import axios from "axios";

export const FiltrosFormatosEnc7 = props => {
    const [encuesta, setState] = useState(props.encuesta);
    const [current, setCurrent] = useState('todo');
    const [tiendas, setTiendas] = useState([]);
    const [tienda, setTienda] = useState(0);
    props.sendData(tienda);

    useEffect(() => {
        (async () => {
            let formData = new FormData();
            formData.append('encuesta', encuesta);
            axios.get(process.env.REACT_APP_URL + 'filtrosformatosEnc7.php')
                .then(response => response.data)
                .then((data) => {
                    setTiendas(data);
                });
        })()
    }, []);
    console.log(tienda);
    return (
        <div id='formatos'>
            <select className="browser-default custom-select form-control-lg" onChange={(e) => setTienda(e.target.value)}>
                <option key={0} value='0' selected={(tienda == '0') ? (true) : (null)}>Todos los restaurantes</option>
                {(tiendas)?(
                    Object.keys(tiendas).map(function (key, index) {
                        return (
                            <option key={key} value={key} selected={(tienda == key) ? (true) : (null)}>{tiendas[key]}</option>
                        )
                    })
                ):(null)

                }
                {/*(tiendas) ? (
                    tiendas.map(tienda => {
                        if (current == 'todo') {
                            return (
                                <option key={tienda.id} value={tienda.id} selected={(tienda == tienda.id) ? (true) : (null)}>{tienda.nombre}</option>
                            )
                        }else if(current == tienda.region){
                            return (
                                <option key={tienda.id} value={tienda.id} selected={(tienda == tienda.id) ? (true) : (null)}>{tienda.nombre}</option>
                            )
                        }
                    })
                ) : (null)*/}
            </select>
        </div >
    );
};
export default FiltrosFormatosEnc7;