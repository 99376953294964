import React, { useState } from 'react';
import CrearEncuesta from '../../components/CrearEncuesta.js';
import ListarEncuestas from '../../components/ListarEncuestas.js';

function Herramientas() {
  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh(!refresh);
  }

  return (
    <div>
        <h1>Herramientas</h1>
      <CrearEncuesta handleRefresh={handleRefresh} />
      <ListarEncuestas refresh={refresh} />
    </div>
  );
}

export default Herramientas;
