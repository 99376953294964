import React, { useState } from "react";
import { connect } from "react-redux";
import AdminUsersTable from "../../components/AdminUsersTable";

const Template = ({ enviarmes, loggedstate }) => {
  
  return (
    <div>
      <h3>En construcción</h3>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    enviarmes: (mes) =>
      dispatch({
        type: "SET_MES",
        mes: mes
      })
  };
};

const mapStateToProps = (state) => ({
  loggedstate: state
});

export default connect(mapStateToProps, mapDispatchToProps)(Template);