import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
    flex: 1,
    height: "1200px",
    width: "100%"
};

class Pantalla4 extends Component {

    getOption = () => {
        const options = {
            toolbox: {
                show: true,
                itemSize: 30,
                feature: {
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top'
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    fontSize: 14,
                    fontWeight: 'bold'
                }
              },
              yAxis: {
                type: 'category',
                data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul'],
                axisLabel: {
                    fontSize: 14,
                    fontWeight: 'bold'
                }
              },
              series: [
                {
                  name: 'Aceptados',
                  color: '#0F0',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: [320, 302, 301, 334, 390, 330, 320]
                },
                {
                  name: 'Rechazados',
                  color: '#F00',
                  type: 'bar',
                  stack: 'total',
                  label: {
                    show: true
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: [120, 132, 101, 134, 90, 230, 210]
                }
              ]
        }
        return options;
    }
    
    render() {
        return (
            <div id="pantalla4" className="contenedorgrafica">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla4);