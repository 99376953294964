import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class Pantalla7 extends Component {

    render() {
        return (
            <div id="pantalla7" className="contenedorgrafica">
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Tienda</Th>
                            <Th>Calificación</Th>
                            <Th># de encuestas</Th>
                        </Tr>
                    </Thead>
                    <Tbody>

                        <Tr>
                            <Td>Liverpool Pachuca (48)</Td><Td >95.63%</Td><Td>8</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Lindavista (Temp POP)</Td><Td >93.33%</Td><Td>6</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio de Hierro Altabrisa</Td><Td >92.36%</Td><Td>129</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Acapulco</Td><Td >92.2%</Td><Td>91</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Paseo La Fe</Td><Td >91.22%</Td><Td>45</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Cuernavaca</Td><Td >91.18%</Td><Td>140</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Town Square</Td><Td >90.44%</Td><Td>148</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Galerias Laguna Torreón</Td><Td >90.14%</Td><Td>74</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Arcos</Td><Td >90.1%</Td><Td>52</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Cancún</Td><Td >89.81%</Td><Td>27</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Galerías Vallart</Td><Td >89.58%</Td><Td>48</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Villacoapa</Td><Td >89.25%</Td><Td>194</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Gran Plaza Gdl</Td><Td >88.41%</Td><Td>157</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Buenavista</Td><Td >88.18%</Td><Td>22</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Chihuahua</Td><Td >88.18%</Td><Td>110</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Tezontle</Td><Td >87.98%</Td><Td>319</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Punto Valle MTY</Td><Td >87.67%</Td><Td>15</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio de Hierro Veracruz</Td><Td >87.6%</Td><Td>25</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Punto Sur</Td><Td >87.6%</Td><Td>25</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Acoxpa</Td><Td >87.54%</Td><Td>175</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Mundo E</Td><Td >87.5%</Td><Td>38</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Coyoacan</Td><Td >87.24%</Td><Td>147</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Satelite</Td><Td >86.96%</Td><Td>349</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Las Antenas</Td><Td >86.8%</Td><Td>100</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Via Vallejo</Td><Td >86.74%</Td><Td>170</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Interlomas</Td><Td >86.67%</Td><Td>81</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Parques Puebla</Td><Td >86.65%</Td><Td>100</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Merida City The Harbour</Td><Td >86.61%</Td><Td>59</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Coapa (6)</Td><Td >86.57%</Td><Td>102</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Mty</Td><Td >86.53%</Td><Td>219</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Puebla (72)</Td><Td >86.43%</Td><Td>21</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Duraznos</Td><Td >86.11%</Td><Td>27</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Monterrey</Td><Td >85.92%</Td><Td>120</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Lindavista (83)</Td><Td >85.83%</Td><Td>174</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Atizapan (104)</Td><Td >85.79%</Td><Td>19</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Interlomas</Td><Td >85.65%</Td><Td>31</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Oasis Coyoacan</Td><Td >85.6%</Td><Td>248</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Insurgentes (2)</Td><Td >85.28%</Td><Td>339</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Satelite</Td><Td >85.16%</Td><Td>157</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Outlet Lerma</Td><Td >85.07%</Td><Td>610</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Parque Delta</Td><Td >84.9%</Td><Td>443</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Parque Delta (81)</Td><Td >84.76%</Td><Td>127</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Gdl</Td><Td >84.74%</Td><Td>272</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Reforma 222</Td><Td >84.55%</Td><Td>278</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Santa Fe</Td><Td >84.54%</Td><Td>240</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Antara</Td><Td >84.4%</Td><Td>393</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Polanco (3)</Td><Td >84.31%</Td><Td>102</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Durango</Td><Td >84.3%</Td><Td>79</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Puebla</Td><Td >84.3%</Td><Td>230</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Centro</Td><Td >84.17%</Td><Td>42</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Toreo</Td><Td >84.14%</Td><Td>257</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Manacar</Td><Td >83.75%</Td><Td>16</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Artz Pedregal</Td><Td >83.16%</Td><Td>95</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Metropoli</Td><Td >83.08%</Td><Td>13</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Puebla</Td><Td >83.04%</Td><Td>186</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Outlet Puebla</Td><Td >82.93%</Td><Td>336</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio de Hierro Antea</Td><Td >82.91%</Td><Td>74</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Perisur (5)</Td><Td >82.86%</Td><Td>105</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Andares</Td><Td >82.81%</Td><Td>212</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Satelite (04)</Td><Td >82.81%</Td><Td>64</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Perisur</Td><Td >82.72%</Td><Td>57</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Punta Norte</Td><Td >82.53%</Td><Td>887</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Lindavista</Td><Td >82.48%</Td><Td>151</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Queretaro</Td><Td >82.26%</Td><Td>277</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Perisur</Td><Td >82.08%</Td><Td>383</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Santa Fe</Td><Td >81.32%</Td><Td>174</Td>
                        </Tr>
                        <Tr>
                            <Td>Palacio De Hierro Polanco</Td><Td >80.97%</Td><Td>149</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Boutique Universidad</Td><Td >80.71%</Td><Td>49</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Delta (Temporary POP)</Td><Td >80%</Td><Td>13</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Queretaro (44)</Td><Td>75%</Td><Td>1</Td>
                        </Tr>
                        <Tr>
                            <Td>Swarovski Coyoacan</Td><Td>68.33%</Td><Td>3</Td>
                        </Tr>
                        <Tr>
                            <Td>Liverpool Metepec (74)</Td><Td>40%</Td><Td>1</Td></Tr>
                    </Tbody>
                </Table>

            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla7);