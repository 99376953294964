import React from "react";
import PropTypes from "prop-types";

const Pager = ({ page, totales, onPageChange }) => {
  const goToFirstPage = () => {
    onPageChange(1);
  };

  const goToLastPage = () => {
    onPageChange(totales);
  };

  const goToPrevious20Pages = () => {
    if (page > 20) {
      onPageChange(page - 20);
    } else {
      onPageChange(1);
    }
  };

  const goToNext20Pages = () => {
    if (page + 20 <= totales) {
      onPageChange(page + 20);
    } else {
      onPageChange(totales);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const goToNextPage = () => {
    if (page < totales) {
      onPageChange(page + 1);
    }
  };

  const Button = ({ label }) => {
    const isActive = page === label;
    const className = isActive ? "active" : "";

    const handleClick = () => {
      onPageChange(label);
    };

    return (
      <button className={className} onClick={handleClick}>
        {label}
      </button>
    );
  };

  return (
    <div className="pager">
      <button onClick={goToFirstPage}>&lt;&lt;</button>
      <button onClick={goToPrevious20Pages}>-20</button>
      <button onClick={goToPreviousPage}>&lt;</button>

      {/* Render adjacent pages */}
      {page > 2 && <Button label={page - 2} />}
      {page > 1 && <Button label={page - 1} />}
      <Button label={page} />
      {page < totales && <Button label={page + 1} />}
      {page < totales - 1 && <Button label={page + 2} />}

      <button onClick={goToNextPage}>&gt;</button>
      <button onClick={goToNext20Pages}>+20</button>
      <button onClick={goToLastPage}>&gt;&gt;</button>
    </div>
  );
};

Pager.propTypes = {
  page: PropTypes.number.isRequired,
  totales: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pager;
