import React, { Component, Fragment } from "react";
import { ReactComponent as LogoBlika } from '../img/logo_kitten.svg';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LanguageIcon from '@mui/icons-material/Language';
import Flag from 'react-world-flags';
import { NavLink } from "react-router-dom";
import { ReactComponent as Icon1 } from '../img/01.svg';
import { ReactComponent as Icon2 } from '../img/02.svg';
import { ReactComponent as Icon3 } from '../img/03.svg';
import Dashboard from "@mui/icons-material/Dashboard";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import M1 from '@mui/icons-material/NotListedLocation';
import { connect } from 'react-redux';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.cerrar = this.cerrar.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        document.querySelectorAll('.panel').forEach(e => e.classList.remove("activo"));
        var id = e.currentTarget.dataset.panel;
        var element = document.getElementById(id);
        setTimeout(function () {
            element.classList.add("activo");
        }, 500);
    }
    cerrar(e) {
        //e.currentTarget.parentNode.classList.remove("activo");
        e.currentTarget.closest(".panel").classList.remove("activo");
    }

    render() {
        let encuesta = this.props.loggedstate.vars.encuesta;
        let clienteid = this.props.loggedstate.vars.clienteid;
        if(encuesta == 7){
            return (
                <Fragment>
                    <div id="footer">
                        <LogoBlika id="logo" />
                        <img className="logoclient" src={"./img/"+clienteid+".png"} />
                        <div id="menu">
                            <button id="cruces" type="button" data-panel="pancruce" onClick={this.handleClick}><AnalyticsIcon /></button>
                        </div>
                    </div>
                    <div id="pancruce" className="panel">
                        <ul>
                            <li>
                                <NavLink to="/" onClick={this.cerrar}>
                                    <Dashboard />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/2" onClick={this.cerrar}>
                                    <M1 />
                                    Preguntas por restaurante
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/7" onClick={this.cerrar}>
                                    <ThumbUpIcon />
                                    Ranking de restaurante
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/3" onClick={this.cerrar}>
                                    <ChecklistRtlIcon />
                                    Cuestionarios
                                </NavLink>
                            </li>
                        </ul>
                        <button className="cerrar" type="button" onClick={this.cerrar}>X</button>
    
                    </div>
                </Fragment>
            );
        }else if(encuesta == 10005){
            return (
            <Fragment>
                    <div id="footer">
                        <LogoBlika id="logo" />
                        <img className="logoclient" src={"./img/swarovski-logo.png"} />
                        <div id="menu">
                            <button id="cruces" type="button" data-panel="pancruce" onClick={this.handleClick}><AnalyticsIcon /></button>
                        </div>
                    </div>
                    <div id="pancruce" className="panel">
                        <ul>
                            <li>
                                <NavLink to="/" onClick={this.cerrar}>
                                    <Dashboard />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/3" onClick={this.cerrar}>
                                    <ChecklistRtlIcon />
                                    Cuestionarios
                                </NavLink>
                            </li>
                        </ul>
                        <button className="cerrar" type="button" onClick={this.cerrar}>X</button>
                    </div>
                </Fragment>
            );
        }else{
            return (
                <Fragment>
                    <div id="footer">
                        <LogoBlika id="logo" />
                        <img className="logoclient" src="./img/swarovski-logo.png" />
                        <div id="menu">
                            {/*<button id="help" type="button" data-panel="panhelp" onClick={this.handleClick} ><HelpCenterIcon width="100%" /></button>*/}
                            <button id="lang" type="button" data-panel="panlanguage" onClick={this.handleClick}><LanguageIcon /></button>
                            <button id="cruces" type="button" data-panel="pancruce" onClick={this.handleClick}><AnalyticsIcon /></button>
                        </div>
                    </div>
                    {/*<div id="panhelp" className="panel">
                        <button className="cerrar" type="button" onClick={this.cerrar}>X</button>
                        Panel de ayuda
                    </div>
                    <div id="panlanguage" className="panel">
                        <button className="cerrar" type="button" onClick={this.cerrar}>X</button>
                        <ul>
                            <li><button type="button"><Flag code={"MX"} /> Español</button></li>
                            <li><button type="button"><Flag code={"USA"} /> Inglés</button></li>
                        </ul>
            </div>*/}
                    <div id="pancruce" className="panel">
                        <ul>
                            <li>
                                <NavLink to="/" onClick={this.cerrar}>
                                    <Dashboard />
                                    Indicador General
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/experiencia" onClick={this.cerrar}>
                                    Experiencia del Usuario
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/1" onClick={this.cerrar}>
                                    <Dashboard />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/2" onClick={this.cerrar}>
                                    <M1 />
                                    Preguntas por formato
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/3" onClick={this.cerrar}>
                                    Cuestionarios
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/4" onClick={this.cerrar}>
                                    Encuestas Enviadas
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/5" onClick={this.cerrar}>
                                    Porcentaje Contestadas
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/6" onClick={this.cerrar}>
                                    Encuestas contestadas por formato
                                </NavLink>
                            </li>
    
                            <li>
                                <NavLink to="/7" onClick={this.cerrar}>
                                    Ranking de tiendas
                                </NavLink>
                            </li>
    
                            <li>
                                <NavLink to="/8" onClick={this.cerrar}>
                                    10 tiendas con mayor nivel de respuesta
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/9" onClick={this.cerrar}>
                                    Ventas por Formato
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/10" onClick={this.cerrar}>
                                    Clientes frecuentes
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/11" onClick={this.cerrar}>
                                    Asesores mejor evaluados
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/12" onClick={this.cerrar}>
                                    Asesores con Áreas de Oportunidad
                                </NavLink>
                            </li>
    
                            <li>
                                <NavLink to="/13" onClick={this.cerrar}>
                                    Ventas/Asesor
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/14" onClick={this.cerrar}>
                                    Be Swarovski
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/15" onClick={this.cerrar}>
                                    Categorización de comentarios
                                </NavLink>
                            </li>
    
                        </ul>
                        <button className="cerrar" type="button" onClick={this.cerrar}>X</button>
    
                    </div>
                </Fragment>
            );
        }
        
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Footer);