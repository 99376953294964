import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class Pantalla7Enc7 extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            ranking: []
        };
    }
    peticionAxios() {
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        let formData = new FormData();
        formData.append('cliente', clienteid);
        formData.append('encuesta', encuesta);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'pantalla7Enc7.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    ranking: data.ranking,
                });
            })
            .catch(function (response) {
                //handle error
                //console.log(response)
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.peticionAxios();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.tienda != prevState.tienda) {
            this.peticionAxios();
        }
    }


    render() {
        const { ranking } = this.state;
        return (
            <div id="pantalla7" className="contenedorgrafica">
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Restaurante</Th>
                            <Th>Calificación</Th>
                            <Th># de encuestas</Th>
                        </Tr>
                    </Thead>
                    <Tbody>

                        {ranking.map((linea, index) => (
                            <Tr key={index}>
                                <Td>{linea.nombre}</Td><Td >{linea.calificacion}%</Td><Td>{linea.encuestas}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla7Enc7);