import {
    LOG_OUT,
    SET_VARS,
    SET_REGION,
    SET_REGIONES,
    SET_MESES,
    SET_MES,
    SET_ACTIVOS,
    SET_LANG
} from "../types";

//cada reducer tiene su propio state
const initialState = {
    clienteid: false,
    cliente: false,
    encuesta: false,
    encuestas: false,
    idioma: 'es',
    es: {
        botoneslang: {
            es: 'Español',
            en: 'Inglés'
        },
        meses: {
            1: 'ENE',
            2: 'FEB',
            3: 'MAR',
            4: 'ABR',
            5: 'MAY',
            6: 'JUN',
            7: 'JUL',
            8: 'AGO',
            9: 'SEP',
            10: 'OCT',
            11: 'NOV',
            12: 'DIC'
        }
    },
    en: {
        botoneslang: {
            es: 'Spanish',
            en: 'English'
        },
        meses: {
            1: 'JAN',
            2: 'FEB',
            3: 'MAR',
            4: 'APR',
            5: 'MAY',
            6: 'JUN',
            7: 'JUL',
            8: 'AUG',
            9: 'SEP',
            10: 'OCT',
            11: 'NOV',
            12: 'DEC'
        }
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_VARS:
            return {
                ...state,
                /*clienteid: 1,
                cliente: 'Swarovski',
                encuestas: [1]*/
                clienteid: action.clienteid,
                cliente: action.cliente,
                encuestas: action.encuestas,
                encuesta: action.encuesta
            };
            break;
        case SET_LANG:
            return {
                ...state,
                idioma: action.idioma
            };
            break;
        case LOG_OUT:
            return {
                ...state,
                clienteid: false,
                cliente: false,
                encuesta: false,
                encuestas: false,
            };
            break;
        default:
            return state;

    }
};