import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';

const style = {
    flex: 1,
    height: "400px",
    width: "100%"
};
class HorizontalBar extends Component {
    getOption = () => {
        const { preguntas, calificaciones } = this.props;

        let series = [];
        let labels = [];
        
        Object.keys(calificaciones).map(function (key, index) {
            if(key !=0){
                console.log(key);
              series.push(calificaciones[key]);
                labels.push(preguntas[key]);  
            }
        });

        const options = {
            yAxis: {
                type: 'category',
                data: labels,
                axisLabel: { show: false }
            },
            xAxis: {
                type: 'value'
            },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 10,
                max: 100,
                text: ['Excelente', 'Malo'],
                // Map the score column to color
                dimension: 0,
                inRange: {
                    color: ['#FD665F', '#FFCE34', '#65B581',]
                }
            },
            series: [
                {
                    data: series,
                    type: 'bar',
                    showBackground: true,
                    label: {
                        show: true,
                        formatter: '{b} {c}%',
                        position: 'insideLeft'
                    },
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                }
            ]
        };
        return options;
    }

    render() {
        return (
            <div id="dashexpectativas">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(HorizontalBar);