import React, { useState } from "react";
import axios from "axios";

const EnviarExcel = ({ encuestaId, showEnviarExcel, tipo, fetchEncuestas}) => {
  const [excelFile, setExcelFile] = useState(null);
  const [message, setMessage] = useState('');
  const handleFileInputChange = (event) => {
    setExcelFile(event.target.files[0]);
  };

  //console.log('Regreso: ',regreso);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    const formData = new FormData();
    formData.append("encuesta", encuestaId);
    formData.append("tipo", tipo);
    formData.append("file", excelFile);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}admin/alimentar.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data.success) {
        // handle success response
        console.log("Excel enviado correctamente:", response.data);
  
        fetchEncuestas();
        showEnviarExcel();
      } else {
        // handle error response
        console.error("Failed to send excel file:", response.data.message);
      }
    } catch (error) {
      // handle error response
      console.error("Failed to send excel file:", error);
    }
  };

  return (
    <div>
      <h2>Enviar Excel</h2>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="excel-file">Seleccionar archivo Excel:</label>
          <input
            type="file"
            id="excel-file"
            name="excel-file"
            accept=".xlsx,.xls"
            onChange={handleFileInputChange}
            required
          />
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default EnviarExcel;