import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ModalQR = ({ encuestaId, toggleModal }) => {
    const [regiones, setRegiones] = useState(null);
    const [url, setUrl] = useState(null);

    const fetchRegiones = async (encuestaId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API}encuestas/getregiones?encuesta=${encuestaId}`
            );
            setRegiones(response.data.regiones);
        } catch (error) {
            console.error('Error fetching regiones:', error);
        }
    };

    const fetchUrl = async (encuestaId) => {
        console.log('encuestaId:', encuestaId);
        if (encuestaId) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API}encuestas/getencuesta?id=${encuestaId}`
                );
                if (response.data.datos) {
                    setUrl(response.data.datos.url);
                } else {
                    console.error('Unexpected response:', response.data);
                }
            } catch (error) {
                console.error('Error fetching URL:', error);
            }
        } else {
            console.error('Invalid encuestaId:', encuestaId);
        }
    };

    useEffect(() => {
        fetchRegiones(encuestaId);
        fetchUrl(encuestaId);
    }, [encuestaId]);

    const [qrImage, setQrImage] = useState({});

    const fetchQrCode = async (region) => {
        const url2 = `https://encuestas.grupokanda.com/${url}?formato=${region.formato}&tienda=${region.id}`;
        const encodedUrl = encodeURIComponent(url2);
        const response = await axios.get(`https://api.blika.mx/urls/getqr?url=${encodedUrl}`, { responseType: 'arraybuffer' });
        const base64 = btoa(
            new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        setQrImage({}); // Reset qrImage state
        setQrImage({ [region.id]: `data:image/png;base64,${base64}` });
    };

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h2 className="mb-4">Crear QR</h2>
                <button type="button" className="btn-close" onClick={toggleModal}></button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <label htmlFor="nombre" className="col-sm-2 col-form-label">Encuesta id: {encuestaId}</label>
                    <div className="col-sm-10">
                        {regiones && regiones.map(region => (
                            <div key={region.id}>
                                <h3>{region.nombre}</h3>
                                {qrImage[region.id] ? (
                                    <div>
                                        <img src={qrImage[region.id]} alt="QR Code" />
                                        <a href={qrImage[region.id]} download={`${region.nombre}.png`}>Descargar código QR</a>
                                    </div>
                                ) : (
                                    <button className="btn btn-secondary" onClick={() => fetchQrCode(region)}>Crear código QR</button>
                                )}
                                <hr></hr>
                            </div>
                        ))}
                    </div>
                </div>
                <button type="button" className="btn btn-primary" onClick={toggleModal}>
                    Cancelar
                </button>
            </div>
        </div>
    );
};

export default ModalQR;