import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const style = {
    flex: 1,
    height: "1200px",
    width: "100%"
};

class Pantalla9 extends Component {

    getOption = () => {
        const labels = ['Liverpool', 'Palacio de Hierro', 'Boutique'];
        const values = [9.37, 19.65, 70.97];
        const options = {
            toolbox: {
                show: true,
                itemSize: 30,
                feature: {
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top'
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            yAxis: {
                type: 'category',
                data: labels.reverse(),
                axisLine: { show: false },
                axisLabel: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
            },
            xAxis: {
                type: 'value'
            },
            series: [
                {
                    data: values.reverse(),
                    type: 'pie',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    },
                    label: {
                        formatter: '{c}%',
                        position: 'inside',
                        show: true,
                        fontSize: 22,
                        fontWeight: 'bold'
                    }
                }
            ]
        }
        return options;
    }

    render() {
        return (
            <div id="pantalla9" className="contenedorgrafica">
                <div className="columnas">
                    <div className="columna">
                        <ReactEcharts
                            option={this.getOption()}
                            className="grafica"
                            style={style}
                            notMerge={false}
                            lazyUpdate={true}
                        />
                    </div>
                    <div className="columna">
                        <div className="au-card-inner"><h4>TOP 5</h4><h3 className="title-2"><strong>Liverpool</strong> 9.371%</h3><div className="chart-note-wrap"><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Liverpool Insurgentes (2) <ArrowRightAltIcon /> <strong>28.494%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Liverpool Lindavista (83) <ArrowRightAltIcon /> <strong>13.668%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Liverpool Parque Delta (81) <ArrowRightAltIcon /> <strong>12.869%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Liverpool Perisur (5) <ArrowRightAltIcon /> <strong>11.115%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Liverpool Polanco (3) <ArrowRightAltIcon /> <strong>10.809%</strong></span></div></div></div>

                        <div className="au-card-inner"><h4>TOP 5</h4><h3 className="title-2" ><strong>Palacio de Hierro</strong> 19.656%</h3><div className="chart-note-wrap"><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Palacio De Hierro Monterrey <ArrowRightAltIcon /> <strong>12.932%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Palacio De Hierro Andares <ArrowRightAltIcon /> <strong>12.871%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Palacio De Hierro Santa Fe <ArrowRightAltIcon /> <strong>11.177%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Palacio De Hierro Polanco <ArrowRightAltIcon /> <strong>9.6%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Palacio De Hierro Puebla <ArrowRightAltIcon /> <strong>9.116%</strong></span></div></div></div>

                        <div className="au-card-inner"><h4>TOP 5</h4><h3 className="title-2" ><strong>Boutique</strong> 70.973%</h3><div className="chart-note-wrap"><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Swarovski Boutique Punta Norte <ArrowRightAltIcon /> <strong>9.673%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Swarovski Boutique Antara <ArrowRightAltIcon /> <strong>7.951%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Swarovski Boutique Outlet Lerma <ArrowRightAltIcon /> <strong>5.893%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Swarovski Boutique Perisur <ArrowRightAltIcon /> <strong>5.213%</strong></span></div><div className="chart-note mr-0 d-block"><span className="dot" ></span><span>Swarovski Boutique Parque Delta <ArrowRightAltIcon /> <strong>5.208%</strong></span></div></div></div>
                    </div>

                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla9);