import React, { useState, useEffect } from "react";
import axios from "axios";

export const FiltrosFormatos = props => {
    const [encuesta, setState] = useState(props.encuesta);
    const [current, setCurrent] = useState('todo');
    const [tiendas, setTiendas] = useState([]);
    const [tienda, setTienda] = useState([]);

    useEffect(() => {
        (async () => {
            let formData = new FormData();
            formData.append('encuesta', encuesta);
            axios.get(process.env.REACT_APP_URL + 'filtrosformatos.php')
                .then(response => response.data)
                .then((data) => {
                    setTiendas(data);
                    console.log('Data from axios: ', data);
                    console.log('State value: ', tiendas);
                });
        })()
    }, []);

    (tiendas) ? (console.log(tienda)) : (console.log('Aquí: ', tiendas));
    return (
        <div id='formatos'>
            <button className={current == 'todo' ? 'active' : null} onClick={() => setCurrent('todo')}>Todo</button>
            <button className={current == '1' ? ' active' : null} onClick={() => setCurrent('1')}>Liverpool</button>
            <button className={current == '2' ? 'active' : null} onClick={() => setCurrent('2')}>El Palacio de Hierro</button>
            <button className={current == '3' ? 'active' : null} onClick={() => setCurrent('3')}>Boutique</button>
            <select className="browser-default custom-select form-control-lg" onChange={(e) => setTienda(e.target.value)}>
                <option key={0} value='0' selected={(tienda == '0') ? (true) : (null)}>Seleccione una tienda</option>
                {(tiendas) ? (
                    tiendas.map(tienda => {
                        if (current == 'todo') {
                            return (
                                <option key={tienda.id} value={tienda.id} selected={(tienda == tienda.id) ? (true) : (null)}>{tienda.nombre}</option>
                            )
                        }else if(current == tienda.region){
                            return (
                                <option key={tienda.id} value={tienda.id} selected={(tienda == tienda.id) ? (true) : (null)}>{tienda.nombre}</option>
                            )
                        }
                    })
                ) : (null)}
            </select>
        </div >
    );
};
export default FiltrosFormatos;