import {
    LOG_IN,
    LOG_OUT

} from "../types";

//cada reducer tiene su propio state
const initialState = {
   logged: false,
    user: false,
    permiso: false,
    username: false,
    /*logged: true,
    user: 1,
    permiso: 1,
    username: false,*/
}

export default function(state = initialState, action){
    switch(action.type){
        case LOG_IN:
            return {
                ...state,
                logged: true,
                user: parseInt(action.userid),
                permiso: parseInt(action.permiso),
                username: action.username,
            };
        break;
        case LOG_OUT:
            return {
                ...state,
                logged: false,
                user: false,
                permiso: false,
                username: false,
            };
        break;
        default:
            return state;

    }
};