import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import { FormErrors } from './FormErrors.js';
import { ReactComponent as Logo } from '../img/logo_kitten.svg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      token: false,
      usuario: '',
      password: '',
      formErrors: { usuario: '', password: '' },
      usuarioValid: false,
      passwordValid: false,
      formValid: false,
      cliente: props.params.cliente,
      datoscliente: {
        id: false,
        nombre: false,
        status: false,
        modulos: false
      }
    };
  }

  componentDidMount() {
    this.props.salir();
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      { [name]: value },
      () => { this.validateField(name, value) });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let usuarioValid = this.state.usuarioValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case 'usuario':
        usuarioValid = value.length >= 3;
        fieldValidationErrors.usuario = usuarioValid ? '' : 'Necesita ingresar un usuario válido';
        break;
      case 'password':
        passwordValid = value.length >= 8;
        fieldValidationErrors.password = passwordValid ? '' : 'La contraseña debe ser mayor a 8 caracteres';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      usuarioValid: usuarioValid,
      passwordValid: passwordValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({ formValid: this.state.usuarioValid && this.state.passwordValid });
  }
  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }
  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();
    formData.append('usuario', this.state.usuario);
    formData.append('password', this.state.password);
    //this.props.entrar(1, 'Fito', 1);//quitar
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'login.php',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ success: data.resultados });
        //if(this.state.success == 1 || this.state.success == 2){
        if (this.state.success == 1) {
          //document.getElementById("closeinicio").click();
          this.props.montarvariables(data.clienteid, false, data.encuestas, data.encuesta);
          this.props.entrar(data.userid, data.username, data.permiso);
        }
      })
      .catch(function (response) {
        //handle error
        //console.log(response)
      });
  }


  render() {
    //console.log(this.props.params);
    return (
      <Fragment>

        <form id="login" onSubmit={e => this.handleFormSubmit(e)}>
          <Logo />
          <div>
            <span>Usuario</span><input autoCorrect="off" autoCapitalize="none" className={`form-control ${this.errorClass(this.state.formErrors.usuario)}`} name="usuario" value={this.state.usuario} onChange={(event) => this.handleUserInput(event)} type="usuario" placeholder="Usuario" /> {this.state.success === 0 ? (<p className="has-error">Este usuario no está registrado</p>) : ('')}
          </div>
          <div>
            <span>Contraseña</span><input autoCorrect="off" autoCapitalize="none" className={`form-control ${this.errorClass(this.state.formErrors.password)}`} name="password" type="password" value={this.state.password} onChange={(event) => this.handleUserInput(event)} placeholder="Contraseña" /> {this.state.success === 2 ? (<p className="has-error">Usuario o contraseña incorrecta</p>) : ('')} {this.state.success === 3 ? (<p className="has-error">Usuario sin permisos para esta área, contacte a un administrador.</p>) : ('')}
          </div>
          <div>
            <span></span><button type="submit" disabled={!this.state.formValid}>ENTRAR</button>
          </div>
        </form>

        <FormErrors formErrors={this.state.formErrors} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loggedstate: state
})


const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    entrar: (userid, username, permiso) => dispatch({
      type: 'LOG_IN', userid: userid, username: username, permiso: permiso,
    }),
    montarvariables: (clienteid, cliente, encuestas, encuesta) => dispatch({
      type: 'SET_VARS', clienteid: clienteid, cliente: cliente, encuestas: encuestas, encuesta: encuesta
    }),
    salir: () => dispatch({ type: 'LOG_OUT' }),
    dispatch,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));