import React, { useState, useEffect } from "react";
import axios from "axios";
import EnviarExcel from "./EnviarExcel";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';

const ListarEncuestas = () => {
  const [encuestas, setEncuestas] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [clients, setClients] = useState([]);
  const [showEnviarExcel, setShowEnviarExcel] = useState(false);
  const [selectedEncuesta, setSelectedEncuesta] = useState(null);
  const [tipoArchivo, setTipoArchivo] = useState(null);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      fetchEncuestas(selectedClient);
    }
  }, [selectedClient]);

  const fetchClients = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}admin/get_clients.php`
    );
    setClients(response.data.clients);
  };

  const fetchEncuestas = async (clientId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}admin/listar_encuestas.php?client=${clientId}`
    );
    setEncuestas(response.data);
  };

  const handleFilterChange = (event) => {
    setSelectedClient(event.target.value);
    setSelectedEncuesta(null);
    setShowEnviarExcel(false);
  };

  const subirArchivo = (encuesta, tipo) => {
    setTipoArchivo(tipo);
    setSelectedEncuesta(encuesta);
    setShowEnviarExcel(true);
  };

  const descargarArchivo = (encuesta) => {
    window.open(`${process.env.REACT_APP_URL}admin/reportes.php?encuesta=${encuesta.id}`); 
    setSelectedEncuesta(null);
  };

  return (
    <div  className='adminelement'>
      <h2>Listar Encuestas</h2>
      <div>
        <label htmlFor="client-filter">Filtrar por Cliente: </label>
        <select id="client-filter" onChange={handleFilterChange}>
          <option value="">Seleccione un cliente</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.nombre}
            </option>
          ))}
        </select>
      </div>
      <ul>
  {!Array.isArray(encuestas) || encuestas.length === 0 ? (
    <li>No hay encuestas para este cliente</li>
  ) : (
    encuestas.map((encuesta) => (
      <li key={encuesta.id}>
        <strong>
          {encuesta.nombre + ' '}
        </strong>
        {(encuesta.preguntas == false) ? (
          <button className="preguntas" onClick={() => subirArchivo(encuesta, 1)}>
            <UploadFileIcon />Subir preguntas
          </button>
        ) : ('')}
        {(encuesta.preguntasmultiples == true) ? (
          (encuesta.respuestas == false) ? (
            <button className="respuestas" onClick={() => subirArchivo(encuesta, 2)}>
              <UploadFileIcon />Subir respuestas
            </button>
          ) : ('jala1')
        ) : ('')}
        {(encuesta.preguntas == true) ? (
          (encuesta.preguntasmultiples == true) ? (
            (encuesta.respuestas == true) ? (
              <button className="descargar" onClick={() => descargarArchivo(encuesta)}>
                <DownloadIcon />Descargar resultados
              </button>
            ) : ('')
          ) : (
            <button className="descargar" onClick={() => descargarArchivo(encuesta)}>
              <DownloadIcon />Descargar resultados
            </button>
          )
        ) : ('')}
        {selectedEncuesta && selectedEncuesta.id === encuesta.id && showEnviarExcel && (
          <EnviarExcel
            encuestaId={selectedEncuesta.id}
            showEnviarExcel={() => setShowEnviarExcel(false)}
            tipo={tipoArchivo}
            fetchEncuestas={() => fetchEncuestas(selectedClient)}
          />
        )}
      </li>
    ))
  )}
</ul>

    </div>
  );
};

export default ListarEncuestas;
