import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
    flex: 1,
    height: "1200px",
    width: "100%"
};

class Pantalla5 extends Component {

    getOption = () => {
        const options = {
            toolbox: {
                show: true,
                itemSize: 30,
                feature: {
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top'
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            polar: {
                radius: [30, '80%']
              },
              radiusAxis: {
                max: 80,
                axisLabel: {
                    fontSize: 22,
                    fontWeight: 'bold',
                }
              },
              angleAxis: {
                type: 'category',
                data: ['Boutique', 'Liverpool', 'El Palacio de Hierro'],
                startAngle: 0,
                axisLabel: {
                    fontSize: 22,
                    fontWeight: 'bold',
                }
              },
              tooltip: {},
              series: {
                type: 'bar',
                data: [73, 10, 16],
                coordinateSystem: 'polar',
                label: {
                  show: true,
                  position: 'middle',
                  formatter: '{c}%',
                  fontSize: 22,
                    fontWeight: 'bold',
                }
              },
              animation: true
        }
        return options;
    }
    
    render() {
        return (
            <div id="pantalla5" className="contenedorgrafica">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla5);