import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import { Fragment } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import PaidIcon from '@mui/icons-material/Paid';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

import DashPreguntas from '../components/DashPreguntas';
import DashExperiencia from "../components/DashExperiencia";
import DashExpectativas from "../components/DashExpectativas";
import HorizontalBar from "../components/HorizontalBar";
import RoundedDona from "../components/RoundedDona";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false
        };
    }
    peticionAxios() {
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        let formData = new FormData();
        formData.append('cliente', clienteid);
        formData.append('encuesta', encuesta);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'dashboard.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ data: data });
            })
            .catch(function (response) {
                //handle error
                //console.log(response)
            });
    }

    componentDidMount() {
        this.peticionAxios()
    }

    render() {
        if (this.state.data) {
            const { numencuestas, tickets, ventas, comentarios, calificaciones, preguntas, experiencia, duales, expectativas, encuestastop, encuestasbottom, cuestionariostop, cuestionariosbottom } = this.state.data;
            return (
                <Fragment>
                    <div className="grid col4">
                        <div className="general">
                            <AccountCircleIcon />
                            <div className="text">
                                <h2>{numencuestas}</h2>
                                <span>encuestas</span>
                            </div>
                        </div>
                        <div className="general">
                            <PointOfSaleIcon />
                            <div className="text">
                                <h2>{tickets}</h2>
                                <span>tickets</span>
                            </div>
                        </div>
                        <div className="general">
                            <NetworkCheckIcon />
                            <div className="text">
                                <h2>{calificaciones[0]}%</h2>
                                <span>calificación</span>
                            </div>
                        </div>
                        <div className="general">
                            <PaidIcon />
                            <div className="text">
                                <h2>{ventas}</h2>
                                <span>ventas</span>
                            </div>
                        </div>
                    </div>
                    <div className="swipperContainer">
                        <Swiper

                            slidesPerView={1}
                            spaceBetween={30}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="swiper"
                        >
                            {comentarios.map((comentario, index) => (
                                <SwiperSlide key={index}>
                                    <blockquote className="quote-card"><p>{comentario.texto}</p><cite>-{comentario.cliente}-</cite><cite><strong>{comentario.tienda}</strong> {comentario.vendedor}</cite></blockquote>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <h2>Preguntas</h2>
                    <HorizontalBar preguntas={preguntas} calificaciones={calificaciones} />
                    <h2>Expectativas</h2>
                    <DashExpectativas preguntas={preguntas} expectativas={expectativas} />
                    <h2>Experiencia</h2>
                    <DashExperiencia preguntas={preguntas} experiencia={experiencia} />
                    <h3>Plan de Lealtad</h3>
                    <div className="flexgraphs">
                    {duales.map((preguntas) => (
                        <div>
                            <h4>{preguntas.pregunta}</h4>
                            <RoundedDona pregunta={preguntas.pregunta} si={preguntas.si} no={preguntas.no}/>
                        </div>
                    ))}
                    </div>
                    <h3>TOP 5</h3>
                    <div className="grid">
                        <div>
                        <h4>Encuestas por tienda</h4>
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>Tienda</th>
                                        <th className="text-right">Encuestas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {encuestastop.map((encuesta, index) => (
                                        <tr key={index}><td>{encuesta.nombre}</td><td className="text-right">{encuesta.encuestas}</td></tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                        <h4>Calificación</h4>
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>Tienda</th>
                                        <th className="text-right">Porcentaje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {cuestionariostop.map((encuesta, index) => (
                                        <tr key={index}><td>{encuesta.nombre}</td><td className="text-right">{encuesta.calificacion}%</td></tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <h3>BOTTOM 5</h3>
                    <div className="grid">
                        <div>
                        <h4>Encuestas por tienda</h4>
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>Tienda</th>
                                        <th className="text-right">Encuestas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {encuestasbottom.map((encuesta, index) => (
                                        <tr key={index}><td>{encuesta.nombre}</td><td className="text-right">{encuesta.encuestas}</td></tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                        <h4>Calificación</h4>
                            <table className="table table-borderless table-striped table-earning">
                                <thead>
                                    <tr>
                                        <th>Tienda</th>
                                        <th className="text-right">Porcentaje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {cuestionariosbottom.map((encuesta, index) => (
                                        <tr key={index}><td>{encuesta.nombre}</td><td className="text-right">{encuesta.calificacion}%</td></tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <div>Cargando</div>
            )
        }
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);