import React, { useEffect, useState } from 'react';
import EncuestaEditor from '../../components/EncuestaEditor.js';
import AdminEncuestasTable from '../../components/AdminEncuestasTable.js';
import axios from 'axios';

function Encuestas() {
  const [listado, setListado] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [editing, setEditing] = useState(null);
  //const [editing, setEditing] = useState(10);

  useEffect(() => {
    if (editing !== null) {
        setListado(false);
    }
}, [editing]);


  const handleListado = (estado) => {
    setListado(estado);
  }

  const handleRefresh = () => {
    setRefresh(!refresh);
  }

  return (
    <div className='container'>
      <h1>Encuestas</h1>
      <div>
        <button type="button" className="btn btn-primary" onClick={() => handleListado(true)}>Listar Encuestas</button>
        <button type="button" className="btn btn-primary" onClick={() => handleListado(false)}>CrearEncuesta</button>
      </div>
      {listado ? (
        <div>
          <AdminEncuestasTable setEditing={setEditing} />
        </div>
      ) : (
        <div>
          <EncuestaEditor id={editing} />
        </div>
      )}
    </div>
  );
}

export default Encuestas;
