import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

class Pantalla13 extends Component {

    render() {
        return (
            <div id="re_generales">
                <h3 className="title-2">Ventas totales: $345,364,494</h3>
                <ol>
                    <li><b>Diana Lopez</b> - <i>Swarovski Boutique Punta Norte</i> : <h3>$7,868,077 <ArrowRightAltIcon /> <i>2.2782%</i></h3></li>
                    <li><b>Xochitl Sanchez</b> - <i>Swarovski Boutique Punta Norte</i> : <h3>$7,030,376 <ArrowRightAltIcon /> <i>2.0356%</i></h3></li>
                    <li><b>Cristobal Pascual</b> - <i>Swarovski Boutique Antara</i> : <h3>$5,333,604 <ArrowRightAltIcon /> <i>1.5443%</i></h3></li>
                    <li><b>Nancy Angeles</b> - <i>Swarovski Boutique Outlet Lerma</i> : <h3>$4,631,257 <ArrowRightAltIcon /> <i>1.341%</i></h3></li>
                    <li><b>Jose Juan Solis</b> - <i>Swarovski Boutique Punta Norte</i> : <h3>$4,241,897 <ArrowRightAltIcon /> <i>1.2282%</i></h3></li>
                    <li><b>Anabel Ordonez</b> - <i>Swarovski Boutique Santa Fe</i> : <h3>$4,079,208 <ArrowRightAltIcon /> <i>1.1811%</i></h3></li>
                    <li><b>Amairani Diaz</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$4,002,370 <ArrowRightAltIcon /> <i>1.1589%</i></h3></li>
                    <li><b>Javier Macias</b> - <i>Palacio De Hierro Andares</i> : <h3>$3,954,113 <ArrowRightAltIcon /> <i>1.1449%</i></h3></li>
                    <li><b>Aaron Rios</b> - <i>Palacio De Hierro Monterrey</i> : <h3>$3,822,439 <ArrowRightAltIcon /> <i>1.1068%</i></h3></li>
                    <li><b>Luis Lopez</b> - <i>Swarovski Boutique Santa Fe</i> : <h3>$3,791,930 <ArrowRightAltIcon /> <i>1.098%</i></h3></li>
                    <li><b>Monica Eleno</b> - <i>Swarovski Boutique Outlet Lerma</i> : <h3>$3,690,917 <ArrowRightAltIcon /> <i>1.0687%</i></h3></li>
                    <li><b>Maria Del Carmen Maldonado</b> - <i>Swarovski Boutique Tezontle</i> : <h3>$3,611,086 <ArrowRightAltIcon /> <i>1.0456%</i></h3></li>
                    <li><b>Angelica Martinez</b> - <i>Swarovski Boutique Antara</i> : <h3>$3,592,657 <ArrowRightAltIcon /> <i>1.0403%</i></h3></li>
                    <li><b>Marlen Alejandra Angeles</b> - <i>Palacio De Hierro Satelite</i> : <h3>$3,497,885 <ArrowRightAltIcon /> <i>1.0128%</i></h3></li>
                    <li><b>Jacqueline Rosas</b> - <i>Swarovski Boutique Tezontle</i> : <h3>$3,418,469 <ArrowRightAltIcon /> <i>0.9898%</i></h3></li>
                    <li><b>Carlos Santillan</b> - <i>Palacio De Hierro Polanco</i> : <h3>$3,354,412 <ArrowRightAltIcon /> <i>0.9713%</i></h3></li>
                    <li><b>Arturo Huervo</b> - <i>Palacio de Hierro Altabrisa</i> : <h3>$3,164,600 <ArrowRightAltIcon /> <i>0.9163%</i></h3></li>
                    <li><b>Obdulia Galindo</b> - <i>Swarovski Boutique Toreo</i> : <h3>$3,162,474 <ArrowRightAltIcon /> <i>0.9157%</i></h3></li>
                    <li><b>Alvaro Tellez</b> - <i>Swarovski Boutique Satelite</i> : <h3>$3,123,743 <ArrowRightAltIcon /> <i>0.9045%</i></h3></li>
                    <li><b>Jessica Perez</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$3,106,359 <ArrowRightAltIcon /> <i>0.8994%</i></h3></li>
                    <li><b>Kanery Gonzalez</b> - <i>Swarovski Boutique Toreo</i> : <h3>$3,094,549 <ArrowRightAltIcon /> <i>0.896%</i></h3></li>
                    <li><b>Noe Grajeda</b> - <i>Swarovski Boutique Gdl</i> : <h3>$3,059,162 <ArrowRightAltIcon /> <i>0.8858%</i></h3></li>
                    <li><b>Marlen Basurto</b> - <i>Swarovski Boutique Satelite</i> : <h3>$3,007,177 <ArrowRightAltIcon /> <i>0.8707%</i></h3></li>
                    <li><b>Lilia Robles</b> - <i>Swarovski Boutique Outlet Puebla</i> : <h3>$3,004,935 <ArrowRightAltIcon /> <i>0.8701%</i></h3></li>
                    <li><b>Veronica Soria</b> - <i>Palacio De Hierro Santa Fe</i> : <h3>$2,991,581 <ArrowRightAltIcon /> <i>0.8662%</i></h3></li>
                    <li><b>Kimberly Salcedo</b> - <i>Palacio De Hierro Acoxpa</i> : <h3>$2,967,652 <ArrowRightAltIcon /> <i>0.8593%</i></h3></li>
                    <li><b>Rosalba Torres</b> - <i>Swarovski Boutique Outlet Puebla</i> : <h3>$2,965,714 <ArrowRightAltIcon /> <i>0.8587%</i></h3></li>
                    <li><b>Danna Ramirez</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$2,914,430 <ArrowRightAltIcon /> <i>0.8439%</i></h3></li>
                    <li><b>Veronica Vazquez</b> - <i>Palacio De Hierro Santa Fe</i> : <h3>$2,903,383 <ArrowRightAltIcon /> <i>0.8407%</i></h3></li>
                    <li><b>Velia Trejo</b> - <i>Liverpool Lindavista (83)</i> : <h3>$2,894,263 <ArrowRightAltIcon /> <i>0.838%</i></h3></li>
                    <li><b>Nancy Zavala</b> - <i>Palacio De Hierro Andares</i> : <h3>$2,835,433 <ArrowRightAltIcon /> <i>0.821%</i></h3></li>
                    <li><b>Graciela Garcia</b> - <i>Palacio De Hierro Puebla</i> : <h3>$2,716,949 <ArrowRightAltIcon /> <i>0.7867%</i></h3></li>
                    <li><b>Rosa Isela Hernandez</b> - <i>Swarovski Boutique Outlet Lerma</i> : <h3>$2,716,174 <ArrowRightAltIcon /> <i>0.7865%</i></h3></li>
                    <li><b>Elizabeth Vazquez</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$2,710,135 <ArrowRightAltIcon /> <i>0.7847%</i></h3></li>
                    <li><b>Miguel Nicolas</b> - <i>Swarovski Boutique Puebla</i> : <h3>$2,682,255 <ArrowRightAltIcon /> <i>0.7766%</i></h3></li>
                    <li><b>Alejandro Palma</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$2,641,735 <ArrowRightAltIcon /> <i>0.7649%</i></h3></li>
                    <li><b>Pedro Cruz</b> - <i>Swarovski Boutique Oasis Coyoacan</i> : <h3>$2,566,789 <ArrowRightAltIcon /> <i>0.7432%</i></h3></li>
                    <li><b>Ivette Gallegos</b> - <i>Swarovski Boutique Perisur</i> : <h3>$2,547,985 <ArrowRightAltIcon /> <i>0.7378%</i></h3></li>
                    <li><b>Angela Cortes</b> - <i>Swarovski Boutique Puebla</i> : <h3>$2,514,544 <ArrowRightAltIcon /> <i>0.7281%</i></h3></li>
                    <li><b>Paola Perez</b> - <i>Swarovski Boutique Outlet Puebla</i> : <h3>$2,511,416 <ArrowRightAltIcon /> <i>0.7272%</i></h3></li>
                    <li><b>Eric Duran</b> - <i>Swarovski Boutique Antara</i> : <h3>$2,469,824 <ArrowRightAltIcon /> <i>0.7151%</i></h3></li>
                    <li><b>Javier Cortez</b> - <i>Swarovski Boutique Mty</i> : <h3>$2,416,505 <ArrowRightAltIcon /> <i>0.6997%</i></h3></li>
                    <li><b>Paola Ramirez</b> - <i>Palacio de Hierro Antea</i> : <h3>$2,389,825 <ArrowRightAltIcon /> <i>0.692%</i></h3></li>
                    <li><b>Georgina Hernandez</b> - <i>Swarovski Boutique Oasis Coyoacan</i> : <h3>$2,387,766 <ArrowRightAltIcon /> <i>0.6914%</i></h3></li>
                    <li><b>Araceli Vargas</b> - <i>Swarovski Boutique Acapulco</i> : <h3>$2,274,022 <ArrowRightAltIcon /> <i>0.6584%</i></h3></li>
                    <li><b>Antonio Martinez</b> - <i>Swarovski Boutique Antara</i> : <h3>$2,231,469 <ArrowRightAltIcon /> <i>0.6461%</i></h3></li>
                    <li><b>Reyna Veronica Cruz</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$2,211,956 <ArrowRightAltIcon /> <i>0.6405%</i></h3></li>
                    <li><b>Melisa Jimenez</b> - <i>Swarovski Boutique Antara</i> : <h3>$2,205,936 <ArrowRightAltIcon /> <i>0.6387%</i></h3></li>
                    <li><b>Jose Chavez</b> - <i>Swarovski Boutique Gran Plaza Gdl</i> : <h3>$2,088,529 <ArrowRightAltIcon /> <i>0.6047%</i></h3></li>
                    <li><b>Samuel Tovar</b> - <i>Palacio De Hierro Monterrey</i> : <h3>$2,086,947 <ArrowRightAltIcon /> <i>0.6043%</i></h3></li>
                    <li><b>Paulo Roberto Flores</b> - <i>Swarovski Boutique Gdl</i> : <h3>$2,072,111 <ArrowRightAltIcon /> <i>0.6%</i></h3></li>
                    <li><b>Virginia Jacqueline Saucedo</b> - <i>Swarovski Boutique Puebla</i> : <h3>$2,045,567 <ArrowRightAltIcon /> <i>0.5923%</i></h3></li>
                    <li><b>Hugo Sanabria</b> - <i>Swarovski Boutique Perisur</i> : <h3>$2,031,180 <ArrowRightAltIcon /> <i>0.5881%</i></h3></li>
                    <li><b>Ruben Catalan</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$2,023,270 <ArrowRightAltIcon /> <i>0.5858%</i></h3></li>
                    <li><b>Anel Guzman</b> - <i>Swarovski Boutique Acapulco</i> : <h3>$2,009,791 <ArrowRightAltIcon /> <i>0.5819%</i></h3></li>
                    <li><b>Erick Sanchez</b> - <i>Swarovski Boutique Punta Norte</i> : <h3>$1,998,811 <ArrowRightAltIcon /> <i>0.5788%</i></h3></li>
                    <li><b>Ana Tiburcio</b> - <i>Swarovski Boutique Villacoapa</i> : <h3>$1,976,851 <ArrowRightAltIcon /> <i>0.5724%</i></h3></li>
                    <li><b>Minerva Rosales</b> - <i>Swarovski Boutique Chihuahua</i> : <h3>$1,943,002 <ArrowRightAltIcon /> <i>0.5626%</i></h3></li>
                    <li><b>Tania Gutierrez</b> - <i>Swarovski Boutique Cuernavaca</i> : <h3>$1,923,202 <ArrowRightAltIcon /> <i>0.5569%</i></h3></li>
                    <li><b>Lizet Cruz</b> - <i>Swarovski Boutique Punta Norte</i> : <h3>$1,888,933 <ArrowRightAltIcon /> <i>0.5469%</i></h3></li>
                    <li><b>Elizabeth Hernandez</b> - <i>Swarovski Boutique Perisur</i> : <h3>$1,865,116 <ArrowRightAltIcon /> <i>0.54%</i></h3></li>
                    <li><b>Jimena Vazquez</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$1,735,855 <ArrowRightAltIcon /> <i>0.5026%</i></h3></li>
                    <li><b>Aldayr Calderon</b> - <i>Swarovski Boutique Cuernavaca</i> : <h3>$1,718,220 <ArrowRightAltIcon /> <i>0.4975%</i></h3></li>
                    <li><b>Karina Bernal</b> - <i>Palacio De Hierro Santa Fe</i> : <h3>$1,692,495 <ArrowRightAltIcon /> <i>0.4901%</i></h3></li>
                    <li><b>Jose Jimenez</b> - <i>Palacio De Hierro Puebla</i> : <h3>$1,640,752 <ArrowRightAltIcon /> <i>0.4751%</i></h3></li>
                    <li><b>Delfina Alonso</b> - <i>Swarovski Boutique Perisur</i> : <h3>$1,630,344 <ArrowRightAltIcon /> <i>0.4721%</i></h3></li>
                    <li><b>Jairo Pinon</b> - <i>Swarovski Boutique Town Square</i> : <h3>$1,627,751 <ArrowRightAltIcon /> <i>0.4713%</i></h3></li>
                    <li><b>Matilde Romero</b> - <i>Palacio De Hierro Durango</i> : <h3>$1,622,704 <ArrowRightAltIcon /> <i>0.4699%</i></h3></li>
                    <li><b>Ulises Luis</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$1,546,766 <ArrowRightAltIcon /> <i>0.4479%</i></h3></li>
                    <li><b>Stephanie Karina Nuno</b> - <i>Swarovski Boutique Gran Plaza Gdl</i> : <h3>$1,545,325 <ArrowRightAltIcon /> <i>0.4474%</i></h3></li>
                    <li><b>Adriana Galindo</b> - <i>Swarovski Boutique Antara</i> : <h3>$1,542,185 <ArrowRightAltIcon /> <i>0.4465%</i></h3></li>
                    <li><b>Diana Karen Maya</b> - <i>Swarovski Boutique Interlomas</i> : <h3>$1,538,194 <ArrowRightAltIcon /> <i>0.4454%</i></h3></li>
                    <li><b>Dulce Ivonne Franco</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$1,515,073 <ArrowRightAltIcon /> <i>0.4387%</i></h3></li>
                    <li><b>Andrea Marin</b> - <i>Swarovski Boutique Satelite</i> : <h3>$1,510,752 <ArrowRightAltIcon /> <i>0.4374%</i></h3></li>
                    <li><b>Arnulfo Pineda</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$1,506,137 <ArrowRightAltIcon /> <i>0.4361%</i></h3></li>
                    <li><b>Cristian Gonzalez</b> - <i>Palacio De Hierro Acoxpa</i> : <h3>$1,476,667 <ArrowRightAltIcon /> <i>0.4276%</i></h3></li>
                    <li><b>Marcela Surdez</b> - <i>Swarovski Galerias Laguna Torreón</i> : <h3>$1,462,509 <ArrowRightAltIcon /> <i>0.4235%</i></h3></li>
                    <li><b>Concepcion Garcia</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$1,460,332 <ArrowRightAltIcon /> <i>0.4228%</i></h3></li>
                    <li><b>Teresa Zuniga</b> - <i>Swarovski Boutique Via Vallejo</i> : <h3>$1,443,626 <ArrowRightAltIcon /> <i>0.418%</i></h3></li>
                    <li><b>Ethan Reyes</b> - <i>Swarovski Boutique Antara</i> : <h3>$1,402,267 <ArrowRightAltIcon /> <i>0.406%</i></h3></li>
                    <li><b>Cristian Gonzalez</b> - <i>Palacio De Hierro Polanco</i> : <h3>$1,396,212 <ArrowRightAltIcon /> <i>0.4043%</i></h3></li>
                    <li><b>Juan Carlos Alvarez</b> - <i>Swarovski Boutique Villacoapa</i> : <h3>$1,395,647 <ArrowRightAltIcon /> <i>0.4041%</i></h3></li>
                    <li><b>Josue Balderas</b> - <i>Palacio De Hierro Andares</i> : <h3>$1,381,329 <ArrowRightAltIcon /> <i>0.4%</i></h3></li>
                    <li><b>Victor Mejia</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$1,371,573 <ArrowRightAltIcon /> <i>0.3971%</i></h3></li>
                    <li><b>Leonardo Linares</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$1,368,199 <ArrowRightAltIcon /> <i>0.3962%</i></h3></li>
                    <li><b>Blanca Ramirez</b> - <i>Liverpool Lindavista (83)</i> : <h3>$1,335,025 <ArrowRightAltIcon /> <i>0.3866%</i></h3></li>
                    <li><b>Brenda Ileana Garcia</b> - <i>Swarovski Boutique Mty</i> : <h3>$1,324,770 <ArrowRightAltIcon /> <i>0.3836%</i></h3></li>
                    <li><b>Martha Martinez</b> - <i>Liverpool Satelite (04)</i> : <h3>$1,312,795 <ArrowRightAltIcon /> <i>0.3801%</i></h3></li>
                    <li><b>Graciela Robles</b> - <i>Swarovski Boutique Galerías Vallart</i> : <h3>$1,292,660 <ArrowRightAltIcon /> <i>0.3743%</i></h3></li>
                    <li><b>Adalberto Marin</b> - <i>Liverpool Polanco (3)</i> : <h3>$1,286,938 <ArrowRightAltIcon /> <i>0.3726%</i></h3></li>
                    <li><b>Beatriz Adriana Fraire</b> - <i>Swarovski Galerias Laguna Torreón</i> : <h3>$1,273,429 <ArrowRightAltIcon /> <i>0.3687%</i></h3></li>
                    <li><b>Daniela Jazmin Ortega</b> - <i>Swarovski Boutique Via Vallejo</i> : <h3>$1,248,695 <ArrowRightAltIcon /> <i>0.3616%</i></h3></li>
                    <li><b>Jorge Torres</b> - <i>Swarovski Boutique Perisur</i> : <h3>$1,230,119 <ArrowRightAltIcon /> <i>0.3562%</i></h3></li>
                    <li><b>Lesli Gonzalez</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$1,220,228 <ArrowRightAltIcon /> <i>0.3533%</i></h3></li>
                    <li><b>Juan Gallegos</b> - <i>Swarovski Boutique Interlomas</i> : <h3>$1,207,061 <ArrowRightAltIcon /> <i>0.3495%</i></h3></li>
                    <li><b>Nora Ballesteros</b> - <i>Swarovski Boutique Outlet Lerma</i> : <h3>$1,192,768 <ArrowRightAltIcon /> <i>0.3454%</i></h3></li>
                    <li><b>Brenda Guadarrama</b> - <i>Swarovski Boutique Satelite</i> : <h3>$1,189,444 <ArrowRightAltIcon /> <i>0.3444%</i></h3></li>
                    <li><b>Yesenia Cruz</b> - <i>Swarovski Boutique Arcos</i> : <h3>$1,180,631 <ArrowRightAltIcon /> <i>0.3419%</i></h3></li>
                    <li><b>Diego Peraza</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$1,175,979 <ArrowRightAltIcon /> <i>0.3405%</i></h3></li>
                    <li><b>Silvia Gonzalez</b> - <i>Palacio De Hierro Perisur</i> : <h3>$1,172,681 <ArrowRightAltIcon /> <i>0.3395%</i></h3></li>
                    <li><b>Thalia Olvera</b> - <i>Palacio De Hierro Puebla</i> : <h3>$1,150,273 <ArrowRightAltIcon /> <i>0.3331%</i></h3></li>
                    <li><b>Priscila Aguilar</b> - <i>Palacio De Hierro Monterrey</i> : <h3>$1,147,264 <ArrowRightAltIcon /> <i>0.3322%</i></h3></li>
                    <li><b>Jeanette Gutierrez</b> - <i>Swarovski Boutique Lindavista</i> : <h3>$1,127,645 <ArrowRightAltIcon /> <i>0.3265%</i></h3></li>
                    <li><b>Janin Valerio</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$1,121,146 <ArrowRightAltIcon /> <i>0.3246%</i></h3></li>
                    <li><b>Julio Cesar Franco</b> - <i>Swarovski Boutique Outlet Lerma</i> : <h3>$1,115,143 <ArrowRightAltIcon /> <i>0.3229%</i></h3></li>
                    <li><b>Claudia Martinez</b> - <i>Swarovski Boutique Puebla</i> : <h3>$1,114,055 <ArrowRightAltIcon /> <i>0.3226%</i></h3></li>
                    <li><b>Juana Silva</b> - <i>Palacio De Hierro Cancún</i> : <h3>$1,106,218 <ArrowRightAltIcon /> <i>0.3203%</i></h3></li>
                    <li><b>Juan Carlos Garibay</b> - <i>Palacio De Hierro Interlomas</i> : <h3>$1,098,574 <ArrowRightAltIcon /> <i>0.3181%</i></h3></li>
                    <li><b>Fernanda Macias</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$1,092,103 <ArrowRightAltIcon /> <i>0.3162%</i></h3></li>
                    <li><b>Elizabeth Vazquez</b> - <i>Swarovski Boutique Outlet Lerma</i> : <h3>$1,086,056 <ArrowRightAltIcon /> <i>0.3145%</i></h3></li>
                    <li><b>Osvaldo Medina</b> - <i>Swarovski Boutique Tezontle</i> : <h3>$1,079,145 <ArrowRightAltIcon /> <i>0.3125%</i></h3></li>
                    <li><b>Efren Selaya</b> - <i>Liverpool Perisur (5)</i> : <h3>$1,061,747 <ArrowRightAltIcon /> <i>0.3074%</i></h3></li>
                    <li><b>Jean Gonzalez</b> - <i>Swarovski Boutique Santa Fe</i> : <h3>$1,059,867 <ArrowRightAltIcon /> <i>0.3069%</i></h3></li>
                    <li><b>Analy Garcia</b> - <i>Swarovski Boutique Interlomas</i> : <h3>$1,049,683 <ArrowRightAltIcon /> <i>0.3039%</i></h3></li>
                    <li><b>Maria Magdalena Negrete</b> - <i>Swarovski Boutique Villacoapa</i> : <h3>$1,045,335 <ArrowRightAltIcon /> <i>0.3027%</i></h3></li>
                    <li><b>Rosalba Jaramillo</b> - <i>Swarovski Boutique Mty</i> : <h3>$1,044,840 <ArrowRightAltIcon /> <i>0.3025%</i></h3></li>
                    <li><b>Darinka Hernandez</b> - <i>Swarovski Boutique Oasis Coyoacan</i> : <h3>$1,043,803 <ArrowRightAltIcon /> <i>0.3022%</i></h3></li>
                    <li><b>Karen Lizbeth Bastida</b> - <i>Swarovski Boutique Las Antenas</i> : <h3>$1,036,994 <ArrowRightAltIcon /> <i>0.3003%</i></h3></li>
                    <li><b>Blanca Mendoza</b> - <i>Swarovski Boutique Parques Puebla</i> : <h3>$1,034,745 <ArrowRightAltIcon /> <i>0.2996%</i></h3></li>
                    <li><b>Christian Rodriguez</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$1,033,656 <ArrowRightAltIcon /> <i>0.2993%</i></h3></li>
                    <li><b>Maria De La Luz Munguia</b> - <i>Swarovski Boutique Gdl</i> : <h3>$1,027,378 <ArrowRightAltIcon /> <i>0.2975%</i></h3></li>
                    <li><b>Daniel Perez</b> - <i>Swarovski Boutique Paseo La Fe</i> : <h3>$1,020,933 <ArrowRightAltIcon /> <i>0.2956%</i></h3></li>
                    <li><b>Ismael Martinez</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$1,013,655 <ArrowRightAltIcon /> <i>0.2935%</i></h3></li>
                    <li><b>Maria Del Rosario Villa</b> - <i>Swarovski Boutique Lindavista</i> : <h3>$997,389 <ArrowRightAltIcon /> <i>0.2888%</i></h3></li>
                    <li><b>Cinthia Rodriguez</b> - <i>Swarovski Boutique Interlomas</i> : <h3>$997,262 <ArrowRightAltIcon /> <i>0.2888%</i></h3></li>
                    <li><b>Luis Gonzalez</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$995,415 <ArrowRightAltIcon /> <i>0.2882%</i></h3></li>
                    <li><b>Guillermo Solis</b> - <i>Swarovski Boutique Gran Plaza Gdl</i> : <h3>$983,667 <ArrowRightAltIcon /> <i>0.2848%</i></h3></li>
                    <li><b>Blanca Maria Gomez</b> - <i>Swarovski Boutique Mty</i> : <h3>$982,910 <ArrowRightAltIcon /> <i>0.2846%</i></h3></li>
                    <li><b>Eduardo Ocana</b> - <i>Swarovski Boutique Mty</i> : <h3>$981,911 <ArrowRightAltIcon /> <i>0.2843%</i></h3></li>
                    <li><b>Luis Gonzalez</b> - <i>Swarovski Boutique Tezontle</i> : <h3>$981,340 <ArrowRightAltIcon /> <i>0.2841%</i></h3></li>
                    <li><b>Alitzel Perez</b> - <i>Swarovski Boutique Toreo</i> : <h3>$972,431 <ArrowRightAltIcon /> <i>0.2816%</i></h3></li>
                    <li><b>Claudia Mariana Hernandez</b> - <i>Swarovski Boutique Town Square</i> : <h3>$957,051 <ArrowRightAltIcon /> <i>0.2771%</i></h3></li>
                    <li><b>Liliana Novella</b> - <i>Swarovski Merida City The Harbour</i> : <h3>$954,394 <ArrowRightAltIcon /> <i>0.2763%</i></h3></li>
                    <li><b>Annel De La Rosa</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$923,941 <ArrowRightAltIcon /> <i>0.2675%</i></h3></li>
                    <li><b>Nadia Guzman</b> - <i>Swarovski Boutique Satelite</i> : <h3>$923,558 <ArrowRightAltIcon /> <i>0.2674%</i></h3></li>
                    <li><b>Gabriela Ramirez</b> - <i>Liverpool Satelite (04)</i> : <h3>$921,833 <ArrowRightAltIcon /> <i>0.2669%</i></h3></li>
                    <li><b>Roberto Malagon</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$916,751 <ArrowRightAltIcon /> <i>0.2654%</i></h3></li>
                    <li><b>Lorena Mendez</b> - <i>Swarovski Boutique Outlet Puebla</i> : <h3>$906,444 <ArrowRightAltIcon /> <i>0.2625%</i></h3></li>
                    <li><b>Victor Gutierrez</b> - <i>Swarovski Boutique Santa Fe</i> : <h3>$905,891 <ArrowRightAltIcon /> <i>0.2623%</i></h3></li>
                    <li><b>Luis Avila</b> - <i>Liverpool Perisur (5)</i> : <h3>$895,894 <ArrowRightAltIcon /> <i>0.2594%</i></h3></li>
                    <li><b>Luz Maria Orozco</b> - <i>Palacio De Hierro Centro</i> : <h3>$890,508 <ArrowRightAltIcon /> <i>0.2578%</i></h3></li>
                    <li><b>Mercedes Peralta</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$885,064 <ArrowRightAltIcon /> <i>0.2563%</i></h3></li>
                    <li><b>Maria Elsa Garcia</b> - <i>Swarovski Boutique Chihuahua</i> : <h3>$884,808 <ArrowRightAltIcon /> <i>0.2562%</i></h3></li>
                    <li><b>Hugo Marhx</b> - <i>Swarovski Boutique Via Vallejo</i> : <h3>$877,687 <ArrowRightAltIcon /> <i>0.2541%</i></h3></li>
                    <li><b>Alexia Silva</b> - <i>Swarovski Boutique Duraznos</i> : <h3>$875,393 <ArrowRightAltIcon /> <i>0.2535%</i></h3></li>
                    <li><b>Maria Fernanda Lopez</b> - <i>Swarovski Boutique Lindavista</i> : <h3>$858,849 <ArrowRightAltIcon /> <i>0.2487%</i></h3></li>
                    <li><b>Omar Gonzalez</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$858,554 <ArrowRightAltIcon /> <i>0.2486%</i></h3></li>
                    <li><b>Maria Del Consuelo Yanez</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$835,587 <ArrowRightAltIcon /> <i>0.2419%</i></h3></li>
                    <li><b>Anallely Ramirez</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$834,051 <ArrowRightAltIcon /> <i>0.2415%</i></h3></li>
                    <li><b>Marco Dominguez</b> - <i>Palacio De Hierro Polanco</i> : <h3>$818,982 <ArrowRightAltIcon /> <i>0.2371%</i></h3></li>
                    <li><b>Edgar Soriano</b> - <i>Palacio De Hierro Monterrey</i> : <h3>$812,397 <ArrowRightAltIcon /> <i>0.2352%</i></h3></li>
                    <li><b>Andrea Diaz</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$809,966 <ArrowRightAltIcon /> <i>0.2345%</i></h3></li>
                    <li><b>Ana Escamilla</b> - <i>Swarovski Boutique Universidad</i> : <h3>$797,974 <ArrowRightAltIcon /> <i>0.2311%</i></h3></li>
                    <li><b>Christian Murillo</b> - <i>Swarovski Boutique Gdl</i> : <h3>$786,701 <ArrowRightAltIcon /> <i>0.2278%</i></h3></li>
                    <li><b>Brenda Guadarrama</b> - <i>Swarovski Boutique Mundo E</i> : <h3>$784,964 <ArrowRightAltIcon /> <i>0.2273%</i></h3></li>
                    <li><b>Paulina Lizeth Bustos</b> - <i>Swarovski Boutique Oasis Coyoacan</i> : <h3>$778,049 <ArrowRightAltIcon /> <i>0.2253%</i></h3></li>
                    <li><b>Wendy Mondragon</b> - <i>Swarovski Boutique Acapulco</i> : <h3>$758,178 <ArrowRightAltIcon /> <i>0.2195%</i></h3></li>
                    <li><b>Mariana Garcia</b> - <i>Palacio De Hierro Durango</i> : <h3>$754,048 <ArrowRightAltIcon /> <i>0.2183%</i></h3></li>
                    <li><b>Maria Del Pilar Morales</b> - <i>Liverpool Atizapan (104)</i> : <h3>$746,965 <ArrowRightAltIcon /> <i>0.2163%</i></h3></li>
                    <li><b>Gisselle Monjo</b> - <i>Palacio De Hierro Polanco</i> : <h3>$744,306 <ArrowRightAltIcon /> <i>0.2155%</i></h3></li>
                    <li><b>Jonathan Lopez</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$725,813 <ArrowRightAltIcon /> <i>0.2102%</i></h3></li>
                    <li><b>Kenia Becceril</b> - <i>Swarovski Boutique Town Square</i> : <h3>$721,063 <ArrowRightAltIcon /> <i>0.2088%</i></h3></li>
                    <li><b>Claudia Torres</b> - <i>Palacio de Hierro Altabrisa</i> : <h3>$709,715 <ArrowRightAltIcon /> <i>0.2055%</i></h3></li>
                    <li><b>Perla Gabrielli</b> - <i>Swarovski Boutique Gdl</i> : <h3>$690,193 <ArrowRightAltIcon /> <i>0.1998%</i></h3></li>
                    <li><b>Luis Angel Garcia</b> - <i>Swarovski Boutique Perisur</i> : <h3>$681,164 <ArrowRightAltIcon /> <i>0.1972%</i></h3></li>
                    <li><b>Nadia Servin</b> - <i>Swarovski Boutique Punta Norte</i> : <h3>$681,070 <ArrowRightAltIcon /> <i>0.1972%</i></h3></li>
                    <li><b>Alain Guzman</b> - <i>Swarovski Boutique Via Vallejo</i> : <h3>$663,474 <ArrowRightAltIcon /> <i>0.1921%</i></h3></li>
                    <li><b>Ismael Martinez</b> - <i>Liverpool Coapa (6)</i> : <h3>$647,948 <ArrowRightAltIcon /> <i>0.1876%</i></h3></li>
                    <li><b>Miriam Sandoval</b> - <i>Palacio De Hierro Puebla</i> : <h3>$628,949 <ArrowRightAltIcon /> <i>0.1821%</i></h3></li>
                    <li><b>Ana Escamilla</b> - <i>Swarovski Boutique Oasis Coyoacan</i> : <h3>$620,631 <ArrowRightAltIcon /> <i>0.1797%</i></h3></li>
                    <li><b>Veronica Lopez</b> - <i>Liverpool Perisur (5)</i> : <h3>$617,436 <ArrowRightAltIcon /> <i>0.1788%</i></h3></li>
                    <li><b>Gabriela Alvarez</b> - <i>Palacio De Hierro Monterrey</i> : <h3>$602,604 <ArrowRightAltIcon /> <i>0.1745%</i></h3></li>
                    <li><b>Efrain Blanco</b> - <i>Swarovski Boutique Gdl</i> : <h3>$596,415 <ArrowRightAltIcon /> <i>0.1727%</i></h3></li>
                    <li><b>Hugo Daniel Rojas</b> - <i>Palacio De Hierro Interlomas</i> : <h3>$596,299 <ArrowRightAltIcon /> <i>0.1727%</i></h3></li>
                    <li><b>Jose Cruz</b> - <i>Liverpool Polanco (3)</i> : <h3>$596,076 <ArrowRightAltIcon /> <i>0.1726%</i></h3></li>
                    <li><b>Thalia Olvera</b> - <i>Swarovski Boutique Parques Puebla</i> : <h3>$575,098 <ArrowRightAltIcon /> <i>0.1665%</i></h3></li>
                    <li><b>Rocio Cardenas</b> - <i>Palacio De Hierro Andares</i> : <h3>$566,393 <ArrowRightAltIcon /> <i>0.164%</i></h3></li>
                    <li><b>Gonzalo Garcia</b> - <i>Swarovski Merida City The Harbour</i> : <h3>$550,512 <ArrowRightAltIcon /> <i>0.1594%</i></h3></li>
                    <li><b>Jose Alberto Hernandez</b> - <i>Swarovski Boutique Antara</i> : <h3>$546,105 <ArrowRightAltIcon /> <i>0.1581%</i></h3></li>
                    <li><b>Luis Nava</b> - <i>Swarovski Boutique Perisur</i> : <h3>$538,416 <ArrowRightAltIcon /> <i>0.1559%</i></h3></li>
                    <li><b>Ulises Luis</b> - <i>Liverpool Polanco (3)</i> : <h3>$525,602 <ArrowRightAltIcon /> <i>0.1522%</i></h3></li>
                    <li><b>Socorro Alfaro</b> - <i>Swarovski Boutique Las Antenas</i> : <h3>$519,350 <ArrowRightAltIcon /> <i>0.1504%</i></h3></li>
                    <li><b>Luis Alberto Novalle</b> - <i>Swarovski Galerias Laguna Torreón</i> : <h3>$515,252 <ArrowRightAltIcon /> <i>0.1492%</i></h3></li>
                    <li><b>Daniel Osorio</b> - <i>Swarovski Boutique Arcos</i> : <h3>$511,248 <ArrowRightAltIcon /> <i>0.148%</i></h3></li>
                    <li><b>Rodolfo David Anguiano</b> - <i>Swarovski Boutique Oasis Coyoacan</i> : <h3>$509,127 <ArrowRightAltIcon /> <i>0.1474%</i></h3></li>
                    <li><b>Brian Michel Arriaga</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$504,951 <ArrowRightAltIcon /> <i>0.1462%</i></h3></li>
                    <li><b>Ivan Disner</b> - <i>Swarovski Boutique Villacoapa</i> : <h3>$489,303 <ArrowRightAltIcon /> <i>0.1417%</i></h3></li>
                    <li><b>Alejandra Maldonado</b> - <i>Palacio de Hierro Veracruz</i> : <h3>$487,864 <ArrowRightAltIcon /> <i>0.1413%</i></h3></li>
                    <li><b>Yessica Garcia</b> - <i>Swarovski Boutique Arcos</i> : <h3>$487,183 <ArrowRightAltIcon /> <i>0.1411%</i></h3></li>
                    <li><b>Elizabeth Hernandez</b> - <i>Palacio De Hierro Perisur</i> : <h3>$482,682 <ArrowRightAltIcon /> <i>0.1398%</i></h3></li>
                    <li><b>Rosa Maria Morales</b> - <i>Liverpool Coapa (6)</i> : <h3>$475,066 <ArrowRightAltIcon /> <i>0.1376%</i></h3></li>
                    <li><b>Zulma Yazmin Villalobos</b> - <i>Swarovski Boutique Town Square</i> : <h3>$471,567 <ArrowRightAltIcon /> <i>0.1365%</i></h3></li>
                    <li><b>David Castillo</b> - <i>Liverpool Puebla (72)</i> : <h3>$464,666 <ArrowRightAltIcon /> <i>0.1345%</i></h3></li>
                    <li><b>America Fuentes</b> - <i>Swarovski Boutique Town Square</i> : <h3>$458,212 <ArrowRightAltIcon /> <i>0.1327%</i></h3></li>
                    <li><b>Rosalba Blancas</b> - <i>Swarovski Boutique Perisur</i> : <h3>$458,030 <ArrowRightAltIcon /> <i>0.1326%</i></h3></li>
                    <li><b>Dulce Martinez</b> - <i>Swarovski Boutique Cuernavaca</i> : <h3>$439,787 <ArrowRightAltIcon /> <i>0.1273%</i></h3></li>
                    <li><b>Luz Ibarra</b> - <i>Liverpool Polanco (3)</i> : <h3>$435,278 <ArrowRightAltIcon /> <i>0.126%</i></h3></li>
                    <li><b>Michelle Gallegos</b> - <i>Palacio De Hierro Perisur</i> : <h3>$425,731 <ArrowRightAltIcon /> <i>0.1233%</i></h3></li>
                    <li><b>Sandra Rocha</b> - <i>Palacio De Hierro Perisur</i> : <h3>$420,292 <ArrowRightAltIcon /> <i>0.1217%</i></h3></li>
                    <li><b>Anayely Galicia</b> - <i>Swarovski Boutique Satelite</i> : <h3>$393,862 <ArrowRightAltIcon /> <i>0.114%</i></h3></li>
                    <li><b>Denis Rodriguez</b> - <i>Liverpool Satelite (04)</i> : <h3>$389,722 <ArrowRightAltIcon /> <i>0.1128%</i></h3></li>
                    <li><b>Karla Lopez</b> - <i>Palacio De Hierro Satelite</i> : <h3>$378,239 <ArrowRightAltIcon /> <i>0.1095%</i></h3></li>
                    <li><b>Salvador Barrientos</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$374,129 <ArrowRightAltIcon /> <i>0.1083%</i></h3></li>
                    <li><b>Rosalba Jaramillo</b> - <i>Swarovski Boutique Punto Valle MTY</i> : <h3>$370,967 <ArrowRightAltIcon /> <i>0.1074%</i></h3></li>
                    <li><b>Annel De La Rosa</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$363,639 <ArrowRightAltIcon /> <i>0.1053%</i></h3></li>
                    <li><b>Polet Castellanos</b> - <i>Swarovski Boutique Perisur</i> : <h3>$362,697 <ArrowRightAltIcon /> <i>0.105%</i></h3></li>
                    <li><b>Andres Nuno</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$361,582 <ArrowRightAltIcon /> <i>0.1047%</i></h3></li>
                    <li><b>Beatriz Alondra Hernandez</b> - <i>Swarovski Boutique Perisur</i> : <h3>$358,494 <ArrowRightAltIcon /> <i>0.1038%</i></h3></li>
                    <li><b>Diana Diaz</b> - <i>Swarovski Boutique Chihuahua</i> : <h3>$356,573 <ArrowRightAltIcon /> <i>0.1032%</i></h3></li>
                    <li><b>Jose Suarez</b> - <i>Palacio De Hierro Interlomas</i> : <h3>$355,391 <ArrowRightAltIcon /> <i>0.1029%</i></h3></li>
                    <li><b>Nayeli Garcia</b> - <i>Liverpool Coapa (6)</i> : <h3>$348,373 <ArrowRightAltIcon /> <i>0.1009%</i></h3></li>
                    <li><b>Cynthia Alamos</b> - <i>Palacio de Hierro Antea</i> : <h3>$339,130 <ArrowRightAltIcon /> <i>0.0982%</i></h3></li>
                    <li><b>Fernanda Macias</b> - <i>Swarovski Boutique Tezontle</i> : <h3>$336,529 <ArrowRightAltIcon /> <i>0.0974%</i></h3></li>
                    <li><b>Mercedes Peralta</b> - <i>Liverpool Coapa (6)</i> : <h3>$332,765 <ArrowRightAltIcon /> <i>0.0964%</i></h3></li>
                    <li><b>Maria Macias</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$330,301 <ArrowRightAltIcon /> <i>0.0956%</i></h3></li>
                    <li><b>Isaias Chavez</b> - <i>Liverpool Perisur (5)</i> : <h3>$330,025 <ArrowRightAltIcon /> <i>0.0956%</i></h3></li>
                    <li><b>Pamela Huerta</b> - <i>Swarovski Boutique Chihuahua</i> : <h3>$329,917 <ArrowRightAltIcon /> <i>0.0955%</i></h3></li>
                    <li><b>Claudia Ivette Vargas</b> - <i>Swarovski Boutique Santa Fe</i> : <h3>$326,474 <ArrowRightAltIcon /> <i>0.0945%</i></h3></li>
                    <li><b>Fatima Guadalupe Salazar</b> - <i>Palacio De Hierro Satelite</i> : <h3>$324,648 <ArrowRightAltIcon /> <i>0.094%</i></h3></li>
                    <li><b>Rodolfo Alva</b> - <i>Swarovski Buenavista</i> : <h3>$322,519 <ArrowRightAltIcon /> <i>0.0934%</i></h3></li>
                    <li><b>Claudia Garcia</b> - <i>Palacio De Hierro Monterrey</i> : <h3>$307,282 <ArrowRightAltIcon /> <i>0.089%</i></h3></li>
                    <li><b>Tania Magali Mendoza</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$307,248 <ArrowRightAltIcon /> <i>0.089%</i></h3></li>
                    <li><b>Arnulfo Pineda</b> - <i>Swarovski Metropoli</i> : <h3>$307,006 <ArrowRightAltIcon /> <i>0.0889%</i></h3></li>
                    <li><b>Arnulfo Pineda</b> - <i>Swarovski Delta (Temporary POP)</i> : <h3>$303,990 <ArrowRightAltIcon /> <i>0.088%</i></h3></li>
                    <li><b>Rosalba Islas</b> - <i>Liverpool Pachuca (48)</i> : <h3>$302,742 <ArrowRightAltIcon /> <i>0.0877%</i></h3></li>
                    <li><b>Edwin Torres</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$300,231 <ArrowRightAltIcon /> <i>0.0869%</i></h3></li>
                    <li><b>Lesli Gonzalez</b> - <i>Liverpool Coapa (6)</i> : <h3>$295,336 <ArrowRightAltIcon /> <i>0.0855%</i></h3></li>
                    <li><b>Laura Requena</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$293,598 <ArrowRightAltIcon /> <i>0.085%</i></h3></li>
                    <li><b>Axel Odin Bautista</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$293,552 <ArrowRightAltIcon /> <i>0.085%</i></h3></li>
                    <li><b>Luis Alberto Martinez</b> - <i>Swarovski Boutique Perisur</i> : <h3>$289,324 <ArrowRightAltIcon /> <i>0.0838%</i></h3></li>
                    <li><b>Ivan Jocef Carrillo</b> - <i>Swarovski Boutique Santa Fe</i> : <h3>$289,297 <ArrowRightAltIcon /> <i>0.0838%</i></h3></li>
                    <li><b>Paz Rebeca Gonzalez</b> - <i>Swarovski Boutique Punto Sur</i> : <h3>$283,391 <ArrowRightAltIcon /> <i>0.0821%</i></h3></li>
                    <li><b>Adalberto Marin</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$283,127 <ArrowRightAltIcon /> <i>0.082%</i></h3></li>
                    <li><b>Juan Manuel Garcia</b> - <i>Liverpool Coapa (6)</i> : <h3>$279,667 <ArrowRightAltIcon /> <i>0.081%</i></h3></li>
                    <li><b>Rosa Maria Morales</b> - <i>Liverpool Polanco (3)</i> : <h3>$278,064 <ArrowRightAltIcon /> <i>0.0805%</i></h3></li>
                    <li><b>Sheila Alejandra Pacheco</b> - <i>Swarovski Boutique Gdl</i> : <h3>$270,594 <ArrowRightAltIcon /> <i>0.0784%</i></h3></li>
                    <li><b>Ely Luna</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$267,158 <ArrowRightAltIcon /> <i>0.0774%</i></h3></li>
                    <li><b>Rodrigo Medina</b> - <i>Swarovski Boutique Perisur</i> : <h3>$266,405 <ArrowRightAltIcon /> <i>0.0771%</i></h3></li>
                    <li><b>Ma Reyna Avila</b> - <i>Swarovski Boutique Las Antenas</i> : <h3>$263,369 <ArrowRightAltIcon /> <i>0.0763%</i></h3></li>
                    <li><b>Cynthia Morales</b> - <i>Swarovski Boutique Paseo La Fe</i> : <h3>$262,454 <ArrowRightAltIcon /> <i>0.076%</i></h3></li>
                    <li><b>Diana Ramirez</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$258,911 <ArrowRightAltIcon /> <i>0.075%</i></h3></li>
                    <li><b>Yaneli Concepcion Uh</b> - <i>Swarovski Merida City The Harbour</i> : <h3>$258,101 <ArrowRightAltIcon /> <i>0.0747%</i></h3></li>
                    <li><b>Jesus Romo</b> - <i>Swarovski Merida City The Harbour</i> : <h3>$254,750 <ArrowRightAltIcon /> <i>0.0738%</i></h3></li>
                    <li><b>Cinthia Rodriguez</b> - <i>Swarovski Boutique Duraznos</i> : <h3>$249,076 <ArrowRightAltIcon /> <i>0.0721%</i></h3></li>
                    <li><b>Luis Nava</b> - <i>Swarovski Lindavista (Temp POP)</i> : <h3>$245,650 <ArrowRightAltIcon /> <i>0.0711%</i></h3></li>
                    <li><b>Jessica Guadalupe Huerta</b> - <i>Palacio De Hierro Satelite</i> : <h3>$242,694 <ArrowRightAltIcon /> <i>0.0703%</i></h3></li>
                    <li><b>Adriana Guevara</b> - <i>Swarovski Boutique Parque Delta</i> : <h3>$240,907 <ArrowRightAltIcon /> <i>0.0698%</i></h3></li>
                    <li><b>Dulce Maria Cen</b> - <i>Palacio de Hierro Veracruz</i> : <h3>$238,396 <ArrowRightAltIcon /> <i>0.069%</i></h3></li>
                    <li><b>Adalberto Aguilar</b> - <i>Palacio De Hierro Perisur</i> : <h3>$236,445 <ArrowRightAltIcon /> <i>0.0685%</i></h3></li>
                    <li><b>Yumico Nayelith Sanchez</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$235,371 <ArrowRightAltIcon /> <i>0.0682%</i></h3></li>
                    <li><b>Rodolfo Alva</b> - <i>Swarovski Boutique Lindavista</i> : <h3>$234,536 <ArrowRightAltIcon /> <i>0.0679%</i></h3></li>
                    <li><b>Steven Joshua Aviles</b> - <i>Swarovski Boutique Universidad</i> : <h3>$232,210 <ArrowRightAltIcon /> <i>0.0672%</i></h3></li>
                    <li><b>Miguel Angel Guaso</b> - <i>Liverpool Perisur (5)</i> : <h3>$227,307 <ArrowRightAltIcon /> <i>0.0658%</i></h3></li>
                    <li><b>Saul Martinez</b> - <i>Swarovski Boutique Parques Puebla</i> : <h3>$222,765 <ArrowRightAltIcon /> <i>0.0645%</i></h3></li>
                    <li><b>Irving Sanchez</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$212,053 <ArrowRightAltIcon /> <i>0.0614%</i></h3></li>
                    <li><b>Christian Bianet Garnica</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$211,571 <ArrowRightAltIcon /> <i>0.0613%</i></h3></li>
                    <li><b>Mario Urena</b> - <i>Swarovski Boutique Las Antenas</i> : <h3>$205,762 <ArrowRightAltIcon /> <i>0.0596%</i></h3></li>
                    <li><b>Perla Rocio Higuera</b> - <i>Swarovski Boutique Chihuahua</i> : <h3>$202,406 <ArrowRightAltIcon /> <i>0.0586%</i></h3></li>
                    <li><b>Erick Alejandro Lucas</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$200,118 <ArrowRightAltIcon /> <i>0.0579%</i></h3></li>
                    <li><b>Victoria Espinoza</b> - <i>Swarovski Manacar</i> : <h3>$198,815 <ArrowRightAltIcon /> <i>0.0576%</i></h3></li>
                    <li><b>Mirza Salome Ortiz</b> - <i>Swarovski Boutique Punto Sur</i> : <h3>$198,469 <ArrowRightAltIcon /> <i>0.0575%</i></h3></li>
                    <li><b>Luis Angel Mezo</b> - <i>Palacio de Hierro Veracruz</i> : <h3>$196,804 <ArrowRightAltIcon /> <i>0.057%</i></h3></li>
                    <li><b>Maria De Los Angeles Jimenez</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$193,057 <ArrowRightAltIcon /> <i>0.0559%</i></h3></li>
                    <li><b>Maria De La Luz Munguia</b> - <i>Swarovski Boutique Punto Sur</i> : <h3>$192,154 <ArrowRightAltIcon /> <i>0.0556%</i></h3></li>
                    <li><b>Luis Fernando Rojas</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$190,716 <ArrowRightAltIcon /> <i>0.0552%</i></h3></li>
                    <li><b>Perla Caballero</b> - <i>Swarovski Boutique Galerías Vallart</i> : <h3>$190,052 <ArrowRightAltIcon /> <i>0.055%</i></h3></li>
                    <li><b>Hugo Sanabria</b> - <i>Swarovski Boutique Lindavista</i> : <h3>$189,532 <ArrowRightAltIcon /> <i>0.0549%</i></h3></li>
                    <li><b>Janin Valerio</b> - <i>Liverpool Polanco (3)</i> : <h3>$184,179 <ArrowRightAltIcon /> <i>0.0533%</i></h3></li>
                    <li><b>Erick Becerra</b> - <i>Liverpool Polanco (3)</i> : <h3>$183,328 <ArrowRightAltIcon /> <i>0.0531%</i></h3></li>
                    <li><b>Jose Manuel Tovar</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$181,937 <ArrowRightAltIcon /> <i>0.0527%</i></h3></li>
                    <li><b>Karla Jimenez</b> - <i>Swarovski Boutique Punto Sur</i> : <h3>$179,649 <ArrowRightAltIcon /> <i>0.052%</i></h3></li>
                    <li><b>Paul Luna</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$177,856 <ArrowRightAltIcon /> <i>0.0515%</i></h3></li>
                    <li><b>Jorge Eduardo Gonzalez</b> - <i>Swarovski Boutique Satelite</i> : <h3>$175,023 <ArrowRightAltIcon /> <i>0.0507%</i></h3></li>
                    <li><b>Nadia Servin</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$174,319 <ArrowRightAltIcon /> <i>0.0505%</i></h3></li>
                    <li><b>Rodrigo Alonso</b> - <i>Palacio De Hierro Durango</i> : <h3>$169,958 <ArrowRightAltIcon /> <i>0.0492%</i></h3></li>
                    <li><b>Jael Berenice Barreto</b> - <i>Palacio De Hierro Durango</i> : <h3>$168,274 <ArrowRightAltIcon /> <i>0.0487%</i></h3></li>
                    <li><b>Paulina Lizeth Bustos</b> - <i>Swarovski Boutique Punto Valle MTY</i> : <h3>$168,151 <ArrowRightAltIcon /> <i>0.0487%</i></h3></li>
                    <li><b>Luis Alejandro Mora</b> - <i>Liverpool Puebla (72)</i> : <h3>$164,527 <ArrowRightAltIcon /> <i>0.0476%</i></h3></li>
                    <li><b>Mercedes Peralta</b> - <i>Liverpool Perisur (5)</i> : <h3>$162,855 <ArrowRightAltIcon /> <i>0.0472%</i></h3></li>
                    <li><b>Elizabeth Rojas</b> - <i>Swarovski Boutique Puebla</i> : <h3>$162,506 <ArrowRightAltIcon /> <i>0.0471%</i></h3></li>
                    <li><b>Rubi Arlette Ortega</b> - <i>Palacio De Hierro Polanco</i> : <h3>$156,403 <ArrowRightAltIcon /> <i>0.0453%</i></h3></li>
                    <li><b>Christian Gutierrez</b> - <i>Swarovski Boutique Perisur</i> : <h3>$154,962 <ArrowRightAltIcon /> <i>0.0449%</i></h3></li>
                    <li><b>Ilse Shamara Caro</b> - <i>Swarovski Boutique Galerías Vallart</i> : <h3>$150,621 <ArrowRightAltIcon /> <i>0.0436%</i></h3></li>
                    <li><b>Isis Paola Zepeda</b> - <i>Swarovski Boutique Antara</i> : <h3>$148,334 <ArrowRightAltIcon /> <i>0.0429%</i></h3></li>
                    <li><b>Jairo Sosa</b> - <i>Swarovski Boutique Perisur</i> : <h3>$145,538 <ArrowRightAltIcon /> <i>0.0421%</i></h3></li>
                    <li><b>Leonardo Tonatiuh Hernandez</b> - <i>Swarovski Manacar</i> : <h3>$144,329 <ArrowRightAltIcon /> <i>0.0418%</i></h3></li>
                    <li><b>Alejandra Jaramillo</b> - <i>Palacio De Hierro Acoxpa</i> : <h3>$140,571 <ArrowRightAltIcon /> <i>0.0407%</i></h3></li>
                    <li><b>Juan Carlos Estrada</b> - <i>Liverpool Perisur (5)</i> : <h3>$131,199 <ArrowRightAltIcon /> <i>0.038%</i></h3></li>
                    <li><b>Claudia Angelica Perez</b> - <i>Swarovski Boutique Las Antenas</i> : <h3>$127,817 <ArrowRightAltIcon /> <i>0.037%</i></h3></li>
                    <li><b>Montserrat Negrete</b> - <i>Swarovski Boutique Interlomas</i> : <h3>$124,412 <ArrowRightAltIcon /> <i>0.036%</i></h3></li>
                    <li><b>Esteban Lopez</b> - <i>Liverpool Puebla (72)</i> : <h3>$123,436 <ArrowRightAltIcon /> <i>0.0357%</i></h3></li>
                    <li><b>Kimberly Salcedo</b> - <i>Swarovski Boutique Villacoapa</i> : <h3>$121,854 <ArrowRightAltIcon /> <i>0.0353%</i></h3></li>
                    <li><b>Jose Carlos Ian Plata</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$121,502 <ArrowRightAltIcon /> <i>0.0352%</i></h3></li>
                    <li><b>Guillermo Solis</b> - <i>Swarovski Boutique Galerías Vallart</i> : <h3>$120,189 <ArrowRightAltIcon /> <i>0.0348%</i></h3></li>
                    <li><b>Gerardo Sanchez</b> - <i>Liverpool Pachuca (48)</i> : <h3>$120,031 <ArrowRightAltIcon /> <i>0.0348%</i></h3></li>
                    <li><b>Fernando Oswaldo Perez</b> - <i>Swarovski Boutique Perisur</i> : <h3>$118,968 <ArrowRightAltIcon /> <i>0.0344%</i></h3></li>
                    <li><b>Jose Cruz</b> - <i>Liverpool Lindavista (83)</i> : <h3>$114,735 <ArrowRightAltIcon /> <i>0.0332%</i></h3></li>
                    <li><b>Miguel Galicia</b> - <i>Liverpool Atizapan (104)</i> : <h3>$114,696 <ArrowRightAltIcon /> <i>0.0332%</i></h3></li>
                    <li><b>Dafne Uribe</b> - <i>Swarovski Boutique Arcos</i> : <h3>$114,453 <ArrowRightAltIcon /> <i>0.0331%</i></h3></li>
                    <li><b>Francisco Fuentes</b> - <i>Swarovski Boutique Acapulco</i> : <h3>$109,413 <ArrowRightAltIcon /> <i>0.0317%</i></h3></li>
                    <li><b>Christian Galan</b> - <i>Swarovski Boutique Town Square</i> : <h3>$108,933 <ArrowRightAltIcon /> <i>0.0315%</i></h3></li>
                    <li><b>Angel Rodolfo Figueroa</b> - <i>Swarovski Boutique Via Vallejo</i> : <h3>$108,791 <ArrowRightAltIcon /> <i>0.0315%</i></h3></li>
                    <li><b>Jacqueline Gonzalez</b> - <i>Liverpool Queretaro (44)</i> : <h3>$107,926 <ArrowRightAltIcon /> <i>0.0312%</i></h3></li>
                    <li><b>Victor Mejia</b> - <i>Liverpool Perisur (5)</i> : <h3>$107,858 <ArrowRightAltIcon /> <i>0.0312%</i></h3></li>
                    <li><b>Christian Bianet Garnica</b> - <i>Swarovski Manacar</i> : <h3>$106,318 <ArrowRightAltIcon /> <i>0.0308%</i></h3></li>
                    <li><b>Julia Gonzalez</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$103,716 <ArrowRightAltIcon /> <i>0.03%</i></h3></li>
                    <li><b>Cristian Gonzalez</b> - <i>Palacio De Hierro Centro</i> : <h3>$100,961 <ArrowRightAltIcon /> <i>0.0292%</i></h3></li>
                    <li><b>Efren Selaya</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$100,886 <ArrowRightAltIcon /> <i>0.0292%</i></h3></li>
                    <li><b>Minerva Lovanka Rosales</b> - <i>Swarovski Boutique Chihuahua</i> : <h3>$100,175 <ArrowRightAltIcon /> <i>0.029%</i></h3></li>
                    <li><b>Pamela Topacio Huerta</b> - <i>Swarovski Boutique Chihuahua</i> : <h3>$93,391 <ArrowRightAltIcon /> <i>0.027%</i></h3></li>
                    <li><b>Daniel Castillo</b> - <i>Swarovski Boutique Puebla</i> : <h3>$91,949 <ArrowRightAltIcon /> <i>0.0266%</i></h3></li>
                    <li><b>Gustavo Alberto Sanchez</b> - <i>Palacio De Hierro Acoxpa</i> : <h3>$91,896 <ArrowRightAltIcon /> <i>0.0266%</i></h3></li>
                    <li><b>Alfredo Acosta</b> - <i>Liverpool Queretaro (44)</i> : <h3>$90,468 <ArrowRightAltIcon /> <i>0.0262%</i></h3></li>
                    <li><b>Luis Antonio Lopez</b> - <i>Palacio De Hierro Satelite</i> : <h3>$89,039 <ArrowRightAltIcon /> <i>0.0258%</i></h3></li>
                    <li><b>Nestor Gustavo Sanchez</b> - <i>Swarovski Boutique Duraznos</i> : <h3>$86,227 <ArrowRightAltIcon /> <i>0.025%</i></h3></li>
                    <li><b>Yajahira Vianey Fernandez</b> - <i>Swarovski Boutique Galerías Vallart</i> : <h3>$86,007 <ArrowRightAltIcon /> <i>0.0249%</i></h3></li>
                    <li><b>Ingrid Alitzel Gonzalez</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$84,327 <ArrowRightAltIcon /> <i>0.0244%</i></h3></li>
                    <li><b>Aurora Gonzalez</b> - <i>Swarovski Boutique Mty</i> : <h3>$82,263 <ArrowRightAltIcon /> <i>0.0238%</i></h3></li>
                    <li><b>Azael Diantes</b> - <i>Palacio De Hierro Satelite</i> : <h3>$80,557 <ArrowRightAltIcon /> <i>0.0233%</i></h3></li>
                    <li><b>Christian Gutierrez</b> - <i>Swarovski Boutique Reforma 222</i> : <h3>$76,964 <ArrowRightAltIcon /> <i>0.0223%</i></h3></li>
                    <li><b>Ana Escamilla</b> - <i>Swarovski Coyoacan</i> : <h3>$75,172 <ArrowRightAltIcon /> <i>0.0218%</i></h3></li>
                    <li><b>Sonia Yazmin Robles</b> - <i>Swarovski Boutique Galerías Vallart</i> : <h3>$74,372 <ArrowRightAltIcon /> <i>0.0215%</i></h3></li>
                    <li><b>Carlos Saldana</b> - <i>Swarovski Boutique Puebla</i> : <h3>$72,075 <ArrowRightAltIcon /> <i>0.0209%</i></h3></li>
                    <li><b>Jorge Torres</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$71,061 <ArrowRightAltIcon /> <i>0.0206%</i></h3></li>
                    <li><b>Xitlali Gutierrez</b> - <i>Liverpool Metepec (74)</i> : <h3>$70,866 <ArrowRightAltIcon /> <i>0.0205%</i></h3></li>
                    <li><b>Ivan Disner</b> - <i>Swarovski Manacar</i> : <h3>$70,248 <ArrowRightAltIcon /> <i>0.0203%</i></h3></li>
                    <li><b>Maykel Iraiz Bazan</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$68,395 <ArrowRightAltIcon /> <i>0.0198%</i></h3></li>
                    <li><b>Marco Antonio Gonzalez</b> - <i>Swarovski Boutique Villacoapa</i> : <h3>$66,156 <ArrowRightAltIcon /> <i>0.0192%</i></h3></li>
                    <li><b>Ely Luna</b> - <i>Liverpool Lindavista (83)</i> : <h3>$64,088 <ArrowRightAltIcon /> <i>0.0186%</i></h3></li>
                    <li><b>Ana Karen Reyes</b> - <i>Liverpool Perisur (5)</i> : <h3>$62,824 <ArrowRightAltIcon /> <i>0.0182%</i></h3></li>
                    <li><b>Oscar Colli</b> - <i>Liverpool Metepec (74)</i> : <h3>$62,640 <ArrowRightAltIcon /> <i>0.0181%</i></h3></li>
                    <li><b>Alma Parra</b> - <i>Swarovski Boutique Puebla</i> : <h3>$62,489 <ArrowRightAltIcon /> <i>0.0181%</i></h3></li>
                    <li><b>Hugo Daniel Rojas</b> - <i>Swarovski Boutique Interlomas</i> : <h3>$61,615 <ArrowRightAltIcon /> <i>0.0178%</i></h3></li>
                    <li><b>Jael Garcia</b> - <i>Swarovski Boutique Perisur</i> : <h3>$56,776 <ArrowRightAltIcon /> <i>0.0164%</i></h3></li>
                    <li><b>Lesli Gonzalez</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$55,052 <ArrowRightAltIcon /> <i>0.0159%</i></h3></li>
                    <li><b>Araceli Sandoval</b> - <i>Swarovski Boutique Satelite</i> : <h3>$53,394 <ArrowRightAltIcon /> <i>0.0155%</i></h3></li>
                    <li><b>Karla Berenice Torres</b> - <i>Swarovski Boutique Universidad</i> : <h3>$50,142 <ArrowRightAltIcon /> <i>0.0145%</i></h3></li>
                    <li><b>Alfonso Contreras</b> - <i>Swarovski Boutique Parques Puebla</i> : <h3>$49,915 <ArrowRightAltIcon /> <i>0.0145%</i></h3></li>
                    <li><b>Tzintli Lopez</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$48,985 <ArrowRightAltIcon /> <i>0.0142%</i></h3></li>
                    <li><b>Pamela Danache</b> - <i>Swarovski Boutique Duraznos</i> : <h3>$46,770 <ArrowRightAltIcon /> <i>0.0135%</i></h3></li>
                    <li><b>Jose Francisco Salazar</b> - <i>Swarovski Boutique Punto Valle MTY</i> : <h3>$42,654 <ArrowRightAltIcon /> <i>0.0124%</i></h3></li>
                    <li><b>Karla Romero</b> - <i>Swarovski Boutique Punto Sur</i> : <h3>$42,407 <ArrowRightAltIcon /> <i>0.0123%</i></h3></li>
                    <li><b>Jose Luis Jimenez</b> - <i>Palacio De Hierro Puebla</i> : <h3>$40,159 <ArrowRightAltIcon /> <i>0.0116%</i></h3></li>
                    <li><b>Maria Del Consuelo Yanez</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$36,277 <ArrowRightAltIcon /> <i>0.0105%</i></h3></li>
                    <li><b>Paz Rebeca Gonzalez</b> - <i>Swarovski Boutique Gdl</i> : <h3>$34,149 <ArrowRightAltIcon /> <i>0.0099%</i></h3></li>
                    <li><b>Alexia Silva</b> - <i>Swarovski Boutique Interlomas</i> : <h3>$34,032 <ArrowRightAltIcon /> <i>0.0099%</i></h3></li>
                    <li><b>Martin Enrique Garcia</b> - <i>Liverpool Atizapan (104)</i> : <h3>$33,458 <ArrowRightAltIcon /> <i>0.0097%</i></h3></li>
                    <li><b>Marisol Hernandez</b> - <i>Palacio De Hierro Polanco</i> : <h3>$33,427 <ArrowRightAltIcon /> <i>0.0097%</i></h3></li>
                    <li><b>Denis Rodriguez</b> - <i>Liverpool Parque Delta (81)</i> : <h3>$29,196 <ArrowRightAltIcon /> <i>0.0085%</i></h3></li>
                    <li><b>Maria Magdalena Negrete</b> - <i>Swarovski Boutique Queretaro</i> : <h3>$29,126 <ArrowRightAltIcon /> <i>0.0084%</i></h3></li>
                    <li><b>Dionisia Becerril</b> - <i>Swarovski Boutique Duraznos</i> : <h3>$28,927 <ArrowRightAltIcon /> <i>0.0084%</i></h3></li>
                    <li><b>Yoovani Uriel Gallegos</b> - <i>Swarovski Boutique Punto Valle MTY</i> : <h3>$27,898 <ArrowRightAltIcon /> <i>0.0081%</i></h3></li>
                    <li><b>Rodolfo David Anguiano</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$26,279 <ArrowRightAltIcon /> <i>0.0076%</i></h3></li>
                    <li><b>Veronica Lopez</b> - <i>Liverpool Satelite (04)</i> : <h3>$25,722 <ArrowRightAltIcon /> <i>0.0074%</i></h3></li>
                    <li><b>Diego Pina</b> - <i>Swarovski Boutique Perisur</i> : <h3>$22,860 <ArrowRightAltIcon /> <i>0.0066%</i></h3></li>
                    <li><b>Concepcion Garcia</b> - <i>Palacio de Hierro Antea</i> : <h3>$21,198 <ArrowRightAltIcon /> <i>0.0061%</i></h3></li>
                    <li><b>Carolina Perez</b> - <i>Palacio De Hierro Interlomas</i> : <h3>$21,047 <ArrowRightAltIcon /> <i>0.0061%</i></h3></li>
                    <li><b>Javier Rojas</b> - <i>Swarovski Boutique Punto Valle MTY</i> : <h3>$20,689 <ArrowRightAltIcon /> <i>0.006%</i></h3></li>
                    <li><b>Maria Magdalena Negrete</b> - <i>Swarovski Boutique Perisur</i> : <h3>$20,278 <ArrowRightAltIcon /> <i>0.0059%</i></h3></li>
                    <li><b>Paz Rebeca Gonzalez</b> - <i>Swarovski Boutique Gran Plaza Gdl</i> : <h3>$20,266 <ArrowRightAltIcon /> <i>0.0059%</i></h3></li>
                    <li><b>Eduardo Paredes</b> - <i>Palacio de Hierro Veracruz</i> : <h3>$18,449 <ArrowRightAltIcon /> <i>0.0053%</i></h3></li>
                    <li><b>Oscar Israel Zapata</b> - <i>Swarovski Merida City The Harbour</i> : <h3>$18,232 <ArrowRightAltIcon /> <i>0.0053%</i></h3></li>
                    <li><b>Marina Del Carmen Silva</b> - <i>Swarovski Merida City The Harbour</i> : <h3>$17,579 <ArrowRightAltIcon /> <i>0.0051%</i></h3></li>
                    <li><b>Maria Consuelo Wong</b> - <i>Swarovski Boutique Punto Valle MTY</i> : <h3>$16,903 <ArrowRightAltIcon /> <i>0.0049%</i></h3></li>
                    <li><b>Luis Nava</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$16,481 <ArrowRightAltIcon /> <i>0.0048%</i></h3></li>
                    <li><b>Janin Valerio</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$16,352 <ArrowRightAltIcon /> <i>0.0047%</i></h3></li>
                    <li><b>Arturo Huervo</b> - <i>Swarovski Boutique Antara</i> : <h3>$16,178 <ArrowRightAltIcon /> <i>0.0047%</i></h3></li>
                    <li><b>Obdulia Galindo</b> - <i>Swarovski Boutique Arcos</i> : <h3>$15,687 <ArrowRightAltIcon /> <i>0.0045%</i></h3></li>
                    <li><b>Maria De Los Angeles Jimenez</b> - <i>Liverpool Lindavista (83)</i> : <h3>$15,332 <ArrowRightAltIcon /> <i>0.0044%</i></h3></li>
                    <li><b>Ana Gabriela Morales</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$15,180 <ArrowRightAltIcon /> <i>0.0044%</i></h3></li>
                    <li><b>Monica Venado</b> - <i>Palacio De Hierro Coyoacan</i> : <h3>$14,218 <ArrowRightAltIcon /> <i>0.0041%</i></h3></li>
                    <li><b>Cristian Gonzalez Keller</b> - <i>Palacio De Hierro Polanco</i> : <h3>$13,486 <ArrowRightAltIcon /> <i>0.0039%</i></h3></li>
                    <li><b>Brenda Dareida Martinez</b> - <i>Swarovski Boutique Outlet Lerma</i> : <h3>$12,439 <ArrowRightAltIcon /> <i>0.0036%</i></h3></li>
                    <li><b>Virginia Jacqueline Saucedo</b> - <i>Palacio De Hierro Puebla</i> : <h3>$11,336 <ArrowRightAltIcon /> <i>0.0033%</i></h3></li>
                    <li><b>Ulises David Trinidad</b> - <i>Liverpool Coapa (6)</i> : <h3>$10,840 <ArrowRightAltIcon /> <i>0.0031%</i></h3></li>
                    <li><b>Daniel Rojas</b> - <i>Palacio De Hierro Interlomas</i> : <h3>$9,360 <ArrowRightAltIcon /> <i>0.0027%</i></h3></li>
                    <li><b>Lucero Gabriela Zacarias</b> - <i>Swarovski Boutique Perisur</i> : <h3>$8,928 <ArrowRightAltIcon /> <i>0.0026%</i></h3></li>
                    <li><b>Denis Rodriguez</b> - <i>Liverpool Polanco (3)</i> : <h3>$8,854 <ArrowRightAltIcon /> <i>0.0026%</i></h3></li>
                    <li><b>Matilde Ibarra</b> - <i>Liverpool Coapa (6)</i> : <h3>$7,996 <ArrowRightAltIcon /> <i>0.0023%</i></h3></li>
                    <li><b>David Josimar Barrera</b> - <i>Swarovski Boutique Satelite</i> : <h3>$7,809 <ArrowRightAltIcon /> <i>0.0023%</i></h3></li>
                    <li><b>Yessica Garcia</b> - <i>Swarovski Boutique Artz Pedregal</i> : <h3>$7,700 <ArrowRightAltIcon /> <i>0.0022%</i></h3></li>
                    <li><b>Olivia Urania Mendez</b> - <i>Palacio de Hierro Veracruz</i> : <h3>$6,447 <ArrowRightAltIcon /> <i>0.0019%</i></h3></li>
                    <li><b>Lorena Hernandez</b> - <i>Swarovski Boutique Parques Puebla</i> : <h3>$5,449 <ArrowRightAltIcon /> <i>0.0016%</i></h3></li>
                    <li><b>Luis Alberto Novella</b> - <i>Swarovski Galerias Laguna Torreón</i> : <h3>$4,669 <ArrowRightAltIcon /> <i>0.0014%</i></h3></li>
                    <li><b>Nadia Servin</b> - <i>Palacio de Hierro Antea</i> : <h3>$3,964 <ArrowRightAltIcon /> <i>0.0011%</i></h3></li>
                    <li><b>Eduardo Valdes</b> - <i>Liverpool Coapa (6)</i> : <h3>$3,841 <ArrowRightAltIcon /> <i>0.0011%</i></h3></li>
                    <li><b>Xochitl Sanchez</b> - <i>Swarovski Boutique Perisur</i> : <h3>$3,781 <ArrowRightAltIcon /> <i>0.0011%</i></h3></li>
                    <li><b>Christian Murillo</b> - <i>Swarovski Boutique Punto Sur</i> : <h3>$3,733 <ArrowRightAltIcon /> <i>0.0011%</i></h3></li>
                    <li><b>Noe Grajeda</b> - <i>Swarovski Boutique Gran Plaza Gdl</i> : <h3>$3,690 <ArrowRightAltIcon /> <i>0.0011%</i></h3></li>
                    <li><b>Naomi Monserrath Martinez</b> - <i>Liverpool Atizapan (104)</i> : <h3>$3,259 <ArrowRightAltIcon /> <i>0.0009%</i></h3></li>
                    <li><b>Victor Gutierrez</b> - <i>Swarovski Merida City The Harbour</i> : <h3>$3,188 <ArrowRightAltIcon /> <i>0.0009%</i></h3></li>
                    <li><b>Nestor Daniel Perez</b> - <i>Swarovski Boutique Paseo La Fe</i> : <h3>$2,843 <ArrowRightAltIcon /> <i>0.0008%</i></h3></li>
                    <li><b>Luis Angel Garcia</b> - <i>Palacio De Hierro Acoxpa</i> : <h3>$2,397 <ArrowRightAltIcon /> <i>0.0007%</i></h3></li>
                    <li><b>Christian Murillo</b> - <i>Swarovski Boutique Gran Plaza Gdl</i> : <h3>$1,543 <ArrowRightAltIcon /> <i>0.0004%</i></h3></li>
                    <li><b>Noe Grajeda</b> - <i>Swarovski Boutique Punto Sur</i> : <h3>$1,457 <ArrowRightAltIcon /> <i>0.0004%</i></h3></li>
                    <li><b>Jose De La Rosa</b> - <i>Swarovski Boutique Parques Puebla</i> : <h3>$1,112 <ArrowRightAltIcon /> <i>0.0003%</i></h3></li>
                    <li><b>Jose Rodrigo Mercado</b> - <i>Liverpool Atizapan (104)</i> : <h3>$1,028 <ArrowRightAltIcon /> <i>0.0003%</i></h3></li>
                    <li><b></b> - <i></i> : <h3>$0 <ArrowRightAltIcon /> <i>0%</i></h3></li>
                    <li><b>Qi Sun</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$-172 <ArrowRightAltIcon /> <i>-0%</i></h3></li>
                    <li><b>Brenda Isabel Agonizante Arias</b> - <i>Liverpool Insurgentes (2)</i> : <h3>$-1,974 <ArrowRightAltIcon /> <i>-0.0006%</i></h3></li>
                    <li><b>Mario Urena</b> - <i>Palacio De Hierro Perisur</i> : <h3>$-5,233 <ArrowRightAltIcon /> <i>-0.0015%</i></h3></li>
                    <li><b>Ethan Reyes</b> - <i>Swarovski Boutique Perisur</i> : <h3>$-12,887 <ArrowRightAltIcon /> <i>-0.0037%</i></h3></li>
                </ol>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla13);