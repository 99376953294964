import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class Pantalla3 extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            resultados: []
        };
    }
    peticionAxios() {
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        let formData = new FormData();
        formData.append('cliente', clienteid);
        formData.append('encuesta', encuesta);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'pantalla3Enc7.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    ranking: data.resultados,
                });
            })
            .catch(function (response) {
                //handle error
                //console.log(response)
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.peticionAxios();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.tienda != prevState.tienda) {
            this.peticionAxios();
        }
    }

    render() {
        return (
            <div id="pantalla3" className="contenedorgrafica">
                <Table className="Table Table-borderless Table-striped Table-earning">
                    <Thead>
                        <Tr id="cabezal">
                            <Th>Fecha <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th>Tienda <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th>Region <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th>Vendedor <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th>Calificación <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>2020-03-10</Td><Td>Swarovski Boutique Punta Norte</Td><Td>Boutiques</Td><Td>Jose Juan Solis</Td><Td >100%</Td><Td><a href="cuestionario.php?id=15715">VER</a></Td></Tr><Tr>
                            <Td>2020-03-09</Td><Td>Swarovski Boutique Outlet Lerma</Td><Td>Boutiques</Td><Td>Nancy Angeles</Td><Td >95%</Td><Td><a href="cuestionario.php?id=15633">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Palacio De Hierro Puebla</Td><Td>Palacio de Hierro</Td><Td>Thalia Olvera</Td><Td >100%</Td><Td><a href="cuestionario.php?id=15696">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Queretaro</Td><Td>Boutiques</Td><Td>Leonardo Linares</Td><Td >95%</Td><Td><a href="cuestionario.php?id=15694">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Outlet Puebla</Td><Td>Boutiques</Td><Td>Paola Perez</Td><Td >70%</Td><Td><a href="cuestionario.php?id=15692">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Queretaro</Td><Td>Boutiques</Td><Td>Leonardo Linares</Td><Td >60%</Td><Td><a href="cuestionario.php?id=15670">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Palacio De Hierro Coyoacan</Td><Td>Palacio de Hierro</Td><Td>Axel Odin Bautista</Td><Td >75%</Td><Td><a href="cuestionario.php?id=15712">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Tezontle</Td><Td>Boutiques</Td><Td>Jacqueline Rosas</Td><Td >100%</Td><Td><a href="cuestionario.php?id=15700">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Satelite</Td><Td>Boutiques</Td><Td>Alvaro Tellez</Td><Td >100%</Td><Td><a href="cuestionario.php?id=15655">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Punto Sur</Td><Td>Boutiques</Td><Td>Mirza Salome Ortiz</Td><Td >90%</Td><Td><a href="cuestionario.php?id=15662">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Punto Valle MTY</Td><Td>Boutiques</Td><Td>Yoovani Uriel Gallegos</Td><Td >95%</Td><Td><a href="cuestionario.php?id=15621">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Parque Delta</Td><Td>Boutiques</Td><Td>Danna Ramirez</Td><Td >85%</Td><Td><a href="cuestionario.php?id=15693">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Puebla</Td><Td>Boutiques</Td><Td>Carlos Saldana</Td><Td >90%</Td><Td><a href="cuestionario.php?id=15639">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Palacio De Hierro Santa Fe</Td><Td>Palacio de Hierro</Td><Td>Karina Bernal</Td><Td >100%</Td><Td><a href="cuestionario.php?id=15671">VER</a></Td></Tr><Tr>
                            <Td>2020-03-08</Td><Td>Swarovski Boutique Perisur</Td><Td>Boutiques</Td><Td>Jairo Sosa</Td><Td >100%</Td><Td><a href="cuestionario.php?id=15664">VER</a></Td></Tr></Tbody>
                </Table>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla3);