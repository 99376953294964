import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import DatePicker2 from "../components/DatePicker2";
import FiltrosFormatosEnc7 from '../components/FiltrosFormatosEnc7';
import HorizontalBar from "../components/HorizontalBar";

const style = {
    flex: 1,
    height: "1200px",
    width: "100%"
};

class Pantalla2Enc7 extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            tienda: 0,
            data: false,
            preguntas: [],
            calificaciones: []
        };
    }
    peticionAxios() {
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        let formData = new FormData();
        formData.append('cliente', clienteid);
        formData.append('encuesta', encuesta);
        formData.append('tienda', this.state.tienda);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'pantalla2Enc7.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    preguntas: data.preguntas,
                    calificaciones: data.calificaciones
                });
            })
            .catch(function (response) {
                //handle error
                //console.log(response)
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.peticionAxios();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.tienda != prevState.tienda){
            this.peticionAxios();
        }
    }
    getDatafromChild = (val) => {
        //console.log(val);
        if (this._isMounted && this.state.tienda != val) {
            console.log(val);
            this.setState({ tienda: val });
        }
        
    }

    render() {
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        const {preguntas, calificaciones} = this.state;
        return (
            <div id="pantalla2" className="contenedorgrafica">
                <div id="filtros">
                    {/*<DatePicker2 mindate="2021/09/23" initdate="2022/08/23" maxdate="2022/09/23" />*/}
                    <FiltrosFormatosEnc7 encuesta={encuesta} sendData={(val) => this.getDatafromChild(val)} />
                </div>
                <HorizontalBar preguntas={preguntas} calificaciones={calificaciones} />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla2Enc7);