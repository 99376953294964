import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import DatePicker from '../components/DatePicker';
import DatePicker2 from "../components/DatePicker2";
import FiltrosFormatos from '../components/FiltrosFormatos';

const style = {
    flex: 1,
    height: "1200px",
    width: "100%"
};

class Pantalla2 extends Component {

    getOption = () => {
        const options = {
            toolbox: {
                show: true,
                itemSize: 30,
                feature: {
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top'
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            yAxis: {
                type: 'category',
                data: ['¿Cómo calificaría su experiencia en Swarovski?',
                    'Qué tan probable es que regrese a comprar a Swarovski?',
                    '¿Qué tan probable es que recomiende comprar en Swarovski?',
                    'Comparado con sus expectativas, su experiencia en general en Swarovski fue:',
                    '¿Cómo califica el servicio del asesor?'],
                axisLine: { show: false },
                axisLabel: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
            },
            xAxis: {
                type: 'value'
            },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 10,
                max: 100,
                text: ['Excelente', 'Malo'],
                // Map the score column to color
                dimension: 0,
                inRange: {
                    color: ['#FD665F', '#FFCE34', '#65B581',]
                }
            },
            series: [
                {
                    data: [82.25, 92, 90.25, 78.5, 82.75],
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    },
                    label: {
                        formatter: '{b} {c}%',
                        position: 'insideLeft',
                        show: true,
                        fontSize: 22,
                        fontWeight: 'bold'
                    }
                }
            ]
        }
        return options;
    }

    render() {
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        return (
            <div id="pantalla2" className="contenedorgrafica">
                <div id="filtros">
                    <DatePicker2 mindate="2021/09/23" initdate="2022/08/23" maxdate="2022/09/23" />
                    <FiltrosFormatos encuesta={encuesta} />
                </div>
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla2);