import React, { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale(es);

export const DatePicker2 = props => {
    const [startDate, setStartDate] = useState(new Date(props.initdate));
    const [endDate, setEndDate] = useState(new Date(props.maxdate));
    const [maxDate, setMaxDate] = useState(new Date(props.maxdate));
    const [minDate, setMinDate] = useState(new Date(props.mindate));
    //const [initDate, setInitDate] = useState(new Date(props.initdate));

    function StartDate(fecha){
        let fechaformat = new Date(fecha);
        setStartDate(fecha);
        if(fechaformat > endDate ){
            setEndDate(fecha);
        }
    }

    function EndDate(fecha){
        let fechaformat = new Date(fecha);
        setEndDate(fecha);
        if(fechaformat < startDate ){
            setStartDate(fecha);
        }
    }

    console.log(endDate);

    return (
        <div id="datepicker">
            <div>
                <h4>Fecha inicial</h4>
                <DatePicker
                    locale={es}
                    dateFormat="dd/MMMM/yyyy"
                    selected={startDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date) => StartDate(date)}
                />
            </div>

            <div>
                <h4>Fecha final</h4>
                <DatePicker
                    locale={es}
                    dateFormat="dd/MMMM/yyyy"
                    selected={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date) => EndDate(date)}
                />
            </div>
        </div>
    );
};
export default DatePicker2;