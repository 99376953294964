import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdminEditUser = ({ userId, toggleModal, saveEdit, errorDuplicado }) => {
  const [nombre, setNombre] = useState('');
  const [nombreError, setNombreError] = useState('');
  const [usuario, setUsuario] = useState('');
  const [usuarioError, setUsuarioError] = useState('');
  const [clave, setClave] = useState('');
  const [claveError, setClaveError] = useState('');
  const [permiso, setPermiso] = useState(3);
  const [clientes, setClientes] = useState(null);
  const [cliente, setCliente] = useState(0);
  const [clienteError, setClienteError] = useState('');
  const [estatus, setEstatus] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [encuestas, setEncuestas] = useState(null);
  const [encuesta, setEncuesta] = useState(0);
  useEffect(() => {
    const fetchUser = async () => {
      if (!userId || isNaN(userId)) {
        setNombre('');
        setNombreError('');
        setUsuario('');
        setUsuarioError('');
        setClave('');
        setClaveError('');
        setPermiso(3);
        setCliente(0);
        setClienteError('');
        setEncuesta(0);
        setEstatus(1);
        setIsLoading(false);
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API}usuarios/getuser?id=${userId}`);
          setNombre(response.data.user.nombre);
          setUsuario(response.data.user.usuario);
          setPermiso(response.data.user.permiso);
          setCliente(response.data.user.clienteid);
          setEncuesta(response.data.user.encuesta);
          setEstatus(response.data.user.status);
          setNombreError('');
          setUsuarioError('');
          setClaveError('');
          setClienteError('');
          setIsLoading(false);
        } catch (error) {
          setError('Error al obtener los datos del usuario.');
          console.log('El error', error);
          setIsLoading(false);
        }
      }
    };
    fetchUser();
  }, [userId]);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}clientes/getclientes`);
        setClientes(response.data.clients);
      } catch (error) {
        setError('Error al obtener los clientes.');
        console.error('Error al obtener los clientes:', error);
      }
    };
    fetchClientes();
  }, []);

  useEffect(() => {
    const fetchEncuestas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}encuestas/listencuestas`);
        setEncuestas(response.data.encuestas);
      } catch (error) {
        setError('Error al obtener las encuestas.');
        console.error('Error al obtener las encuestas.', error);
      }
    };
    fetchEncuestas();
  }, [clientes]);

  const clearErrors = () => {
    setNombreError('');
    setUsuarioError('');
    setClaveError('');
    setClienteError('');
  };

  const handleUpdate = async () => {
    clearErrors();

    let isValid = true;

    if (nombre.trim() === '') {
      setNombreError('Debe ingresar un nombre válido.');
      isValid = false;
    }

    if (usuario.trim() === '') {
      setUsuarioError('Debe ingresar un usuario válido.');
      isValid = false;
    }

    if ((cliente === 0 || encuesta === 0) && permiso === 3) {
      setClienteError('Debe ingresar un cliente y encuesta válidos.');
      isValid = false;
    }

    if (!userId || isNaN(userId)) {
      if (clave.length < 8) {
        setClaveError('La clave debe contener al menos 8 caracteres.');
        isValid = false;
      }
    } else if (clave.trim() !== '' && clave.trim().length < 8) {
      setClaveError('La clave debe contener al menos 8 caracteres  o estar vacía.');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API}usuarios/edituser?id=${userId}`, {
        nombre,
        usuario,
        clave,
        permiso,
        cliente,
        estatus,
      });
      if (response.data.message === 'error1') {
        errorDuplicado();
        setUsuarioError('El usuario ya existe.');
      } else {
        setError('');
        console.log('Usuario actualizado exitosamente.');
        saveEdit();
      }
    } catch (error) {
      setError('Error al actualizar el usuario.');
      console.error('Error al actualizar el usuario:', error);
    }
  };

  if (isLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h2 className="mb-4">{(!userId || isNaN(userId)) ? ('Crear Usuario') : ('Editar Usuario ID: ' + userId)}</h2>
        <button type="button" className="btn-close" onClick={toggleModal}></button>
      </div>
      <div className="modal-body">
        <div className="mb-3 row">
          <label htmlFor="nombre" className="col-sm-2 col-form-label">Nombre:</label>
          <div className="col-sm-10">
            <input
              type="text"
              id="nombre"
              className="form-control"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            {nombreError && <span className="text-danger">{nombreError}</span>}
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="usuario" className="col-sm-2 col-form-label">Usuario</label>
          <div className="col-sm-10">
            <input
              type="text"
              id="usuario"
              className="form-control"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              disabled={userId !== false}
            />
            {usuarioError && <span className="text-danger">{usuarioError}</span>}
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="clave" className="col-sm-2 col-form-label">Clave:</label>
          <div className="col-sm-10">
            <input
              type="password"
              id="clave"
              className="form-control"
              value={clave}
              onChange={(e) => setClave(e.target.value)}
            />
            {claveError && <span className="text-danger">{claveError}</span>}
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="permiso" className="col-sm-2 col-form-label">Permiso:</label>
          <div className="col-sm-10">
            <select
              id="permiso"
              className="form-select"
              value={permiso}
              onChange={(e) => setPermiso(parseInt(e.target.value))}
            >
              <option value={1}>Administrador</option>
              <option value={2}>Editor</option>
              <option value={3}>Usuarios</option>
            </select>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="cliente" className="col-sm-2 col-form-label">Cliente:</label>
          <div className="col-sm-10">
            {clientes !== null ? (
              <select
                id="cliente"
                className="form-select"
                value={cliente}
                onChange={(e) => setCliente(parseInt(e.target.value))}
              >
                <option value={0}>Seleccione</option>
                {clientes.map((cliente) => (
                  <option key={cliente.id} value={cliente.id}>{cliente.nombre}</option>
                ))}
              </select>
            ) : (
              <p>Cargando clientes...</p>
            )}
            {clienteError && <span className="text-danger">{clienteError}</span>}
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="encuesta" className="col-sm-2 col-form-label">Encuesta:</label>
          <div className="col-sm-10">
            {encuestas !== null ? (
              <select
                id="encuesta"
                className="form-select"
                value={encuesta}
                onChange={(e) => setEncuesta(parseInt(e.target.value))}
              >
                <option value={0}>Seleccione</option>
                {encuestas.map((encuestach) => (
                  (cliente === encuestach.cliente) ? (
                    <option key={encuestach.id} value={encuestach.id}>{encuestach.nombre}</option>
                  ) : (null)
                ))}
              </select>
            ) : (
              <p>Cargando encuestas...</p>
            )}
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="estatus" className="col-sm-2 col-form-label">Estatus:</label>
          <div className="col-sm-10">
            <select
              id="estatus"
              className="form-select"
              value={estatus}
              onChange={(e) => setEstatus(parseInt(e.target.value))}
            >
              <option value={0}>Inactivo</option>
              <option value={1}>Activo</option>
            </select>
          </div>
        </div>

        <button onClick={handleUpdate} className="btn btn-success">{(!userId || isNaN(userId)) ? ('Crear') : ('Actualizar: ' + userId)}</button>
        <button type="button" className="btn btn-primary" onClick={toggleModal}>
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default AdminEditUser;