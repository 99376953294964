import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Pager from "./Pager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminEditClient from './AdminEditClient';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const AdminClientesTable = () => {
  const [data, setData] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [sort, setSort] = useState({ field: 'id', sort: 'desc' });
  const [status, setStatus] = useState('false');
  const [page, setPage] = useState(1);
  const [totales, setTotales] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [editing, setEditing] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  const handleEdit = (id) => {
    setEditing(id);
    setShowModal(true);
    console.log(id);
  }
  
  const saveEdit = () => {
    setShowModal(false);
    fetchData(page, sort, status);
    toast.success('Cambios salvados', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }
  
  const errorDuplicado = () => {
    toast.error('El nombre de usuario ya se encuentra en uso', {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }

  useEffect(() => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    axios.get(`${process.env.REACT_APP_API}clientes/getclientes`, {
      cancelToken: source.token
    })
      .then(response => {
        setClientes(response.data.clientes);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching client data:', error);
        }
      });
    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  }, []);

  const fetchData = async (page, sort, status) => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}clientes/getclientes?pagina=${page}&sort=${sort.field},${sort.sort}&status=${status}`,
        {
          cancelToken: source.token,
        }
      );

      setData(response.data.clients);
      setTotales(response.data.totalPages);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching client data:', error);
      }
    }

    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  };

  useEffect(() => {
    fetchData(page, sort, status);
  }, [page, sort, status]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [sort, status]);

  const handleDelete = async (id) => {
    if (!window.confirm('¿Está seguro que desea borrar a este cliente? Esto borrará todo sus contenidos relacionados: usuarios, encuestas, resultados.')) {
      return;
    }
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}clientes/deletecliente?id=${id}`,
        {
          cancelToken: source.token,
        }
      );

      console.log('Client deleted successfully:', response.data.message);
      fetchData(page, sort, status);
      toast.info('Cliente borrado correctamente', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error deleting client:', error);
      }
    }

    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div>
            ID
            <button className={`btn ${sort.field === 'id' && sort.sort === 'desc' ? 'active' : ''}`} onClick={() => setSort({ field: 'id', sort: 'desc' })}>
              <ArrowDropUpIcon />
            </button>
            <button className={`btn ${sort.field === 'id' && sort.sort === 'asc' ? 'active' : ''}`} onClick={() => setSort({ field: 'id', sort: 'asc' })}>
              <ArrowDropDownIcon />
            </button>
          </div>
        ),
        accessor: 'id'
      },
      {
        Header: () => (
          <div>
            Nombre
            <button className={`btn ${sort.field === 'nombre' && sort.sort === 'desc' ? 'active' : ''}`} onClick={() => setSort({ field: 'nombre', sort: 'desc' })}>
              <ArrowDropUpIcon />
            </button>
            <button className={`btn ${sort.field === 'nombre' && sort.sort === 'asc' ? 'active' : ''}`} onClick={() => setSort({ field: 'nombre', sort: 'asc' })}>
              <ArrowDropDownIcon />
            </button>
          </div>
        ),
        accessor: 'nombre'
      },
      {
        Header: () => (
          <div>
            Estatus
            <select value={status} onChange={(e) => setStatus(parseInt(e.target.value))}>
              <option value="false">Todos</option>
              <option value="0">Inactivo</option>
              <option value="1">Activo</option>
            </select>
          </div>
        ),
        accessor: 'status'
      },
      {
        Header: 'Acciones',
        Cell: ({ row }) => (
          <div>
            <button onClick={() => handleEdit(row.original.id)}><EditNoteIcon /></button>
            <button onClick={() => handleDelete(row.original.id)}><DeleteForeverIcon /></button>
          </div>
        )
      }
    ],
    [page, sort, status]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pager page={page} totales={totales} onPageChange={setPage} />
        </div>
      </div>

      <div>
        <ToastContainer />
        <button className="btn btn-primary" onClick={() => handleEdit(false)}>
          Nuevo cliente
        </button>

        <div className={`modal ${showModal ? 'show' : ''} large-modal`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
            <AdminEditClient clientId={editing} toggleModal={toggleModal} saveEdit={saveEdit} errorDuplicado={errorDuplicado} />
          </div>
        </div>
        {showModal && <div className="modal-backdrop show" onClick={toggleModal}></div>}
      </div>
    </div>
  );
};

export default AdminClientesTable;
