import React, { useState } from "react";
import { connect } from "react-redux";
import AdminClientesTable from "../../components/AdminClientesTable";

const Clientes = ({ enviarmes, loggedstate }) => {
  
  return (
    <div>
      <AdminClientesTable />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    enviarmes: (mes) =>
      dispatch({
        type: "SET_MES",
        mes: mes
      })
  };
};

const mapStateToProps = (state) => ({
  loggedstate: state
});

export default connect(mapStateToProps, mapDispatchToProps)(Clientes);