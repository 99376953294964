import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdminEditClient = ({ clientId, toggleModal, saveEdit, errorDuplicado }) => {
  const [nombre, setNombre] = useState('');
  const [nombreError, setNombreError] = useState('');
  const [status, setEstatus] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchClient = async () => {
      if (!clientId || isNaN(clientId)) {
        setNombre('');
        setNombreError('');
        setEstatus(1);
        setIsLoading(false);
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API}clientes/getcliente?id=${clientId}`);
          setNombre(response.data.cliente.nombre);
          setEstatus(response.data.cliente.status);
          setNombreError('');
          setIsLoading(false);
        } catch (error) {
          setError('Error al obtener los datos del cliente.');
          console.log('El error', error);
          setIsLoading(false);
        }
      }
    };
    fetchClient();
  }, [clientId]);

  const clearErrors = () => {
    setNombreError('');
  };

  const handleUpdate = async () => {
    clearErrors();

    let isValid = true;

    if (nombre.trim() === '') {
      setNombreError('Debe ingresar un nombre válido.');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API}clientes/editcliente?id=${clientId}`, {
        nombre,
        status,
      });
      if (response.data.message === 'error1') {
        errorDuplicado();
      } else {
        setError('');
        console.log('Cliente actualizado exitosamente.');
        saveEdit();
      }
    } catch (error) {
      setError('Error al actualizar el cliente.');
      console.error('Error al actualizar el cliente:', error);
    }
  };

  if (isLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h2 className="mb-4">{(!clientId || isNaN(clientId)) ? ('Crear Cliente') : ('Editar Cliente ID: ' + clientId)}</h2>
        <button type="button" className="btn-close" onClick={toggleModal}></button>
      </div>
      <div className="modal-body">
        <div className="mb-3 row">
          <label htmlFor="nombre" className="col-sm-2 col-form-label">Nombre:</label>
          <div className="col-sm-10">
            <input
              type="text"
              id="nombre"
              className="form-control"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            {nombreError && <span className="text-danger">{nombreError}</span>}
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="estatus" className="col-sm-2 col-form-label">Estatus:</label>
          <div className="col-sm-10">
            <select
              id="estatus"
              className="form-select"
              value={status}
              onChange={(e) => setEstatus(parseInt(e.target.value))}
            >
              <option value={0}>Inactivo</option>
              <option value={1}>Activo</option>
            </select>
          </div>
        </div>

        <button onClick={handleUpdate} className="btn btn-success">{(!clientId || isNaN(clientId)) ? ('Crear') : ('Actualizar: ' + clientId)}</button>
        <button type="button" className="btn btn-primary" onClick={toggleModal}>
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default AdminEditClient;