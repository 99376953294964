import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import DatePicker from '../components/DatePicker';
import FiltrosFormatos from '../components/FiltrosFormatos';

const style = {
    flex: 1,
    height: "1200px",
    width: "100%"
};

class Pantalla8 extends Component {

    getOption = () => {
        const labels = ['Swarovski Boutique Punta Norte','Swarovski Boutique Outlet Lerma','Swarovski Boutique Parque Delta','Swarovski Boutique Antara','Swarovski Boutique Perisur','Swarovski Boutique Satelite','Liverpool Insurgentes (2)','Swarovski Boutique Outlet Puebla','Swarovski Boutique Tezontle','Swarovski Boutique Reforma 222'];
        const values = [8.344,5.738,4.167,3.697,3.603,3.283,3.189,3.161,3.001,2.615];
        const options = {
            toolbox: {
                show: true,
                itemSize: 30,
                feature: {
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top'
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            yAxis: {
                type: 'category',
                data: labels.reverse(),
                axisLine: { show: false },
                axisLabel: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
            },
            xAxis: {
                type: 'value'
            },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 1,
                max: 10,
                text: ['Excelente', 'Malo'],
                // Map the score column to color
                dimension: 0,
                inRange: {
                    color: ['#FD665F', '#FFCE34', '#65B581']
                }
            },
            series: [
                {
                    data: values.reverse(),
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    },
                    label: {
                        formatter: '{b} / {c}%',
                        position: 'insideLeft',
                        show: true,
                        fontSize: 22,
                        fontWeight: 'bold'
                    }
                }
            ]
        }
        return options;
    }

    render() {
        return (
            <div id="pantalla8" className="contenedorgrafica">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla8);