import './App.css';
import { Provider, useSelector } from 'react-redux';
import store from "./store";
import Landing from './components/Landing';
import Splash from './components/LogoInicio';
import Login from './components/Login';
import Footer from './components/Footer';
import Pantalla0 from './pages/Pantalla0';
import Dashboard from './pages/Dashboard';
import Pantalla2 from './pages/Pantalla2';
import Pantalla3 from './pages/Pantalla3';
import Pantalla4 from './pages/Pantalla4';
import Pantalla5 from './pages/Pantalla5';
import Pantalla6 from './pages/Pantalla6';
import Pantalla7 from './pages/Pantalla7';
import Pantalla8 from './pages/Pantalla8';
import Pantalla9 from './pages/Pantalla9';
import Pantalla10 from './pages/Pantalla10';
import Pantalla11 from './pages/Pantalla11';
import Pantalla12 from './pages/Pantalla12';
import Pantalla13 from './pages/Pantalla13';
import Pantalla14 from './pages/Pantalla14';
import Pantalla15 from './pages/Pantalla15';
import Experiencia from './pages/experienciadelusuario';

import Herramientas from './pages/admin/Herramientas';

import DashboardV2 from './pages/DashboardV2';
import Pantalla2Enc7 from './pages/Pantalla2Enc7';
import Pantalla7Enc7 from './pages/Pantalla7Enc7';
import Pantalla3Enc7 from './pages/Pantalla3Enc7';
import DashboardSw from './pages/DashboardSw';
import Pantalla3Enc10005 from './pages/Pantalla3Enc10005';
import { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  Routes,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

import Administrador from './pages/admin/Administrador';
import Clientes from './pages/admin/Clientes';
import AdminNav from './components/AdminMenu';
import Template from './pages/admin/Template';
import Encuestas from './pages/admin/Encuestas';
import QuestionsAndAnswers from './components/QuestionsAndAnswers';

function Content() {
  const login = useSelector(state => state.login.logged);
  const permiso = useSelector(state => state.login.permiso);
  const encuesta = useSelector(state => state.vars.encuesta);
  if (login && permiso !== 1) {
    return (
      <Fragment>
        <div className="contenedor">
          <Routes>
            <Route path="/administrador" element={<Herramientas />} />
            {encuesta === 7 && (
              <Fragment>
                <Route exact path="/" element={<DashboardV2 />} />
                <Route path="/2" element={<Pantalla2Enc7 />} />
                <Route path="/7" element={<Pantalla7Enc7 />} />
                <Route path="/3" element={<Pantalla3Enc7 />} />
              </Fragment>
            )}
            {encuesta === 10005 && (
              <Fragment>
                <Route exact path="/" element={<DashboardSw />} />
                <Route path="/3" element={<Pantalla3Enc10005 />} />
              </Fragment>
            )}
            {encuesta !== 7 && encuesta !== 10005 && (
              <Fragment>
                <Route exact path="/" element={<Pantalla0 />} />
                <Route exact path="/experiencia" element={<Experiencia />} />
                <Route path="/1" element={<Dashboard />} />
                <Route path="/2" element={<Pantalla2 />} />
                <Route path="/3" element={<Pantalla3 />} />
                <Route path="/4" element={<Pantalla4 />} />
                <Route path="/5" element={<Pantalla5 />} />
                <Route path="/6" element={<Pantalla6 />} />
                <Route path="/7" element={<Pantalla7 />} />
                <Route path="/8" element={<Pantalla8 />} />
                <Route path="/9" element={<Pantalla9 />} />
                <Route path="/10" element={<Pantalla10 />} />
                <Route path="/11" element={<Pantalla11 />} />
                <Route path="/12" element={<Pantalla12 />} />
                <Route path="/13" element={<Pantalla13 />} />
                <Route path="/14" element={<Pantalla14 />} />
                <Route path="/15" element={<Pantalla15 />} />
              </Fragment>
            )}
          </Routes>
        </div>
        <Footer />
      </Fragment>
    );
  } else if (login && permiso === 1) {
    return (
      <Fragment>
        <AdminNav />
        <Routes>
          <Route path="/" element={<Administrador />} />
          <Route path="/herramientas" element={<Herramientas />} />
          <Route path="/encuestas" element={<Encuestas />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="*" element={<Template />} />
        </Routes>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Splash />
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      </Fragment>
    )
  }
}

function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <Router>
          {<Content />}
          {/*<Encuestas />*/}
        </Router>
      </Provider>
    </Fragment>
  );
}

export default App;
