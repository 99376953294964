import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';

const style = {
    flex: 1,
    height: "400px",
    width: "100%"
};

class DashExpectativas extends Component {
    getOption = () => {
        const { preguntas, expectativas } = this.props;

        let series = [];
        let iter = 0;

        Object.keys(expectativas).map(function (key, index) {
            Object.keys(expectativas[key]).map(function (key2, index2) {
                let numero = expectativas[key][key2].numero;
                let texto = expectativas[key][key2].texto;
                series.push({
                    value: numero,
                    name: texto,
                    title: {
                        show: false
                    },
                    detail: {
                        valueAnimation: true,
                        offsetCenter: ['200%', (-100+(20*iter))+'%'],
                        formatter: texto+' {value}%'
                    }
                });
                iter += 1;
            })
        });

        //console.log(series);

        const options = {
            series: [
                {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#464646'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            width: 100
                        }
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: series,
                    title: {
                        fontSize: 14,
                    },
                    detail: {
                        width: 250,
                        height: 14,
                        fontSize: 14,
                        color: 'auto',
                        borderColor: 'auto',
                        borderRadius: 20,
                        borderWidth: 1,
                        formatter: '{value}%'
                    }
                }
            ]
        };
        return options;
    }

    render() {
        return (
            <div id="dashexpectativas">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(DashExpectativas);