import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
const EncuestaEditor = ({ id }) => {
    const [json, setJson] = useState({
        datos: {
            id: false,
            cliente: 0,
            url: '',
            nombre: '',
            metodoscaptura: '2',
            lng: 1,
            default_lng: 1,
        },
        estructura: [],
    });
    const [cancelToken, setCancelToken] = useState(null);
    const [clientes, setClientes] = useState([]);
    const [colapsar, setColapsar] = useState(false);
    useEffect(() => {
        if (cancelToken) {
            cancelToken.cancel('Request canceled: New request triggered.');
        }
        const source = axios.CancelToken.source();
        setCancelToken(source);
        axios
            .get(`${process.env.REACT_APP_API}clientes/getclientes`, {
                cancelToken: source.token,
            })
            .then((response) => {
                setClientes(response.data.clients);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                } else {
                    console.error('Error fetching user data:', error);
                }
            });
        return () => {
            source.cancel('Request canceled: Component unmounted or request canceled.');
        };
    }, []);

    useEffect(() => {
        if (id !== false) {
            if (cancelToken) {
                cancelToken.cancel('Request canceled: New request triggered.');
            }
            const source = axios.CancelToken.source();
            setCancelToken(source);

            axios
                .get(`${process.env.REACT_APP_API}encuestas/getencuesta?id=${id}`, {
                    cancelToken: source.token,
                })
                .then((response) => {
                    setJson(response.data);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                    } else {
                        console.error('Error fetching user data:', error);
                    }
                });
            return () => {
                source.cancel('Request canceled: Component unmounted or request canceled.');
            };
        } else {
            setJson({
                datos: {
                    id: false,
                    cliente: 0,
                    url: '',
                    nombre: '',
                    metodoscaptura: '2',
                    lng: 1,
                    default_lng: 1,
                },
                estructura: [],
            });
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setJson({
            ...json,
            datos: {
                ...json.datos,
                [name]: value,
            },
        });
    };
    const handleSeccionTextChange = (e, seccionId) => {
        const updatedJson = { ...json };
        const seccionIndex = updatedJson.estructura.findIndex(
            (seccion) => seccion.secciondata.id === seccionId
        );
        if (seccionIndex !== -1) {
            updatedJson.estructura[seccionIndex].secciondata.nombre = e.target.value;
            setJson(updatedJson);
        }
    };
    const handlePreguntaTextChange = (e, preguntaId) => {
        const updatedJson = { ...json };
        updatedJson.estructura.forEach((seccion) => {
            const pregunta = seccion.preguntas.find(
                (seccion) => seccion.preguntadata.id === preguntaId
            );
            if (pregunta) {
                pregunta.preguntadata.pregunta = e.target.value;
            }
        });
        setJson(updatedJson);
    };
    const handleRespuestaTextChange = (e, respuestaId) => {
        const updatedJson = { ...json };
        updatedJson.estructura.forEach((seccion) => {
            seccion.preguntas.forEach((pregunta) => {
                const respuesta = pregunta.respuestas.find(
                    (seccion) => seccion.id === respuestaId
                );
                if (respuesta) {
                    respuesta.texto = e.target.value;
                }
            });
        });
        setJson(updatedJson);
    };

    const onDragEnd = (result) => {
        console.log('sourceId:', result.source.droppableId);
        console.log('destinationId:', result.destination.droppableId);
        if (!result.destination) return;
        const sourceId = result.source.droppableId;
        const destinationId = result.destination.droppableId;

        const updatedJson = { ...json };

        if (sourceId === 'encuesta' && destinationId === 'encuesta') {
            console.log('drop seccion ocurred');
            const items = Array.from(updatedJson.estructura);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            updatedJson.estructura = items;
        } else if (sourceId.startsWith('seccion') && destinationId.startsWith('seccion')) {
            console.log('drop pregunta to another seccion ocurred');
            const sourceSeccionId = parseInt(sourceId.replace('seccion', ''));
            const destinationSeccionId = parseInt(destinationId.replace('seccion', ''));

            if (sourceSeccionId === destinationSeccionId) {
                const sourceSeccion = updatedJson.estructura.find((seccion) => seccion.secciondata.id === sourceSeccionId);

                if (sourceSeccion) {
                    const preguntas = Array.from(sourceSeccion.preguntas);
                    const [reorderedPregunta] = preguntas.splice(result.source.index, 1);
                    preguntas.splice(result.destination.index, 0, reorderedPregunta);
                    sourceSeccion.preguntas = preguntas;
                }
            } else {
                console.log('drop pregunta within seccion ocurred');
                const sourceSeccionIndex = updatedJson.estructura.findIndex((seccion) => seccion.secciondata.id === sourceSeccionId);
                const destinationSeccionIndex = updatedJson.estructura.findIndex((seccion) => seccion.secciondata.id === destinationSeccionId);

                if (sourceSeccionIndex !== -1 && destinationSeccionIndex !== -1) {
                    const [movedPregunta] = updatedJson.estructura[sourceSeccionIndex].preguntas.splice(result.source.index, 1);

                    movedPregunta.preguntadata.seccion = destinationSeccionId;
                    movedPregunta.preguntadata.orden = result.destination.index + 1;

                    updatedJson.estructura[destinationSeccionIndex].preguntas.splice(result.destination.index, 0, movedPregunta);
                }
            }
        } else if (sourceId.startsWith('pregunta') && destinationId.startsWith('seccion')) {
            console.log('drop pregunta ocurred');
            const sourcePreguntaId = parseInt(sourceId.replace('pregunta', ''));
            const destinationSeccionId = parseInt(destinationId.replace('seccion', ''));

            const sourceSeccionIndex = updatedJson.estructura.findIndex((seccion) => seccion.secciondata.id === sourcePreguntaId);
            const destinationSeccionIndex = updatedJson.estructura.findIndex((seccion) => seccion.secciondata.id === destinationSeccionId);

            if (sourceSeccionIndex !== -1 && destinationSeccionIndex !== -1) {
                const [movedPregunta] = updatedJson.estructura[sourceSeccionIndex].preguntas.splice(result.source.index, 1);

                movedPregunta.preguntadata.seccion = destinationSeccionId;
                movedPregunta.preguntadata.orden = result.destination.index + 1;

                updatedJson.estructura[destinationSeccionIndex].preguntas.splice(result.destination.index, 0, movedPregunta);
            }
        } else if (sourceId.startsWith('pregunta') && destinationId.startsWith('pregunta')) {
            console.log('drop respuesta ocurred');
            const sourcePreguntaId = parseInt(sourceId.replace('pregunta', ''));
            const destinationPreguntaId = parseInt(destinationId.replace('pregunta', ''));

            const sourceSeccionIndex = updatedJson.estructura.findIndex(
                (seccion) => seccion.preguntas.some((pregunta) => pregunta.preguntadata.id === sourcePreguntaId)
            );

            const destinationSeccionIndex = updatedJson.estructura.findIndex(
                (seccion) => seccion.preguntas.some((pregunta) => pregunta.preguntadata.id === destinationPreguntaId)
            );

            if (sourceSeccionIndex !== -1 && destinationSeccionIndex !== -1) {
                const sourcePregunta = updatedJson.estructura[sourceSeccionIndex].preguntas.find(
                    (pregunta) => pregunta.preguntadata.id === sourcePreguntaId
                );

                const destinationPregunta = updatedJson.estructura[destinationSeccionIndex].preguntas.find(
                    (pregunta) => pregunta.preguntadata.id === destinationPreguntaId
                );

                if (sourcePregunta && sourcePregunta.respuestas && destinationPregunta) {
                    const respuestas = Array.from(sourcePregunta.respuestas);
                    const [reorderedRespuesta] = respuestas.splice(result.source.index, 1);
                    sourcePregunta.respuestas = respuestas; // Update the source pregunta's respuestas array

                    if (sourcePreguntaId === destinationPreguntaId) {
                        // Reordering within the same pregunta
                        respuestas.splice(result.destination.index, 0, reorderedRespuesta);
                    } else {
                        // Moving respuesta to a different pregunta
                        destinationPregunta.respuestas.splice(result.destination.index, 0, reorderedRespuesta);
                    }
                }
            }
        }
        setJson(prevJson => {
            // Create a new object for the json state
            const newJson = JSON.parse(JSON.stringify(prevJson));

            // Update the newJson object with the updatedJson object
            newJson.estructura = updatedJson.estructura;

            return newJson;
        });
    };

    const handleRespuestaType = (e, preguntaId) => {
        const newJson = { ...json };
        const pregunta = newJson.estructura
            .flatMap(seccion => seccion.preguntas)
            .find(pregunta => pregunta.preguntadata.id === preguntaId);

        if (!pregunta) {
            console.error(`Could not find pregunta with ID ${preguntaId}`);
            return;
        }
        pregunta.preguntadata.tipo = e.target.value;
        setJson(newJson);
    };

    const handleDeleteRespuesta = (preguntaId, respuestaId) => {
        const newJson = { ...json };
        const pregunta = newJson.estructura
            .flatMap(seccion => seccion.preguntas)
            .find(pregunta => pregunta.preguntadata.id === preguntaId);

        if (!pregunta) {
            console.error(`Could not find pregunta with ID ${preguntaId}`);
            return;
        }
        pregunta.respuestas = pregunta.respuestas.filter(respuesta => respuesta.id !== respuestaId);
        setJson(newJson);
    };

    const handleAddRespuesta = (preguntaId) => {
        const newJson = { ...json };
        const pregunta = newJson.estructura
            .flatMap(seccion => seccion.preguntas)
            .find(pregunta => pregunta.preguntadata.id === preguntaId);
        if (!pregunta) {
            console.error(`Could not find pregunta with ID ${preguntaId}`);
            return;
        }
        const newRespuesta = {
            id: 'Respuesta-' + generateUniqueId(),
            texto: 'Nueva respuesta',
            valor: '',
        };
        pregunta.respuestas.push(newRespuesta);
        setJson(newJson);
    };

    const handleValorTextChange = (e, itemId, itemType) => {
        const updatedJson = { ...json };

        if (itemType === 'seccion') {
            const seccionIndex = updatedJson.estructura.findIndex(
                (seccion) => seccion.secciondata.id === itemId
            );

            if (seccionIndex !== -1) {
                updatedJson.estructura[seccionIndex].secciondata.valor = e.target.value;
            }
        } else if (itemType === 'pregunta') {
            updatedJson.estructura.forEach((seccion) => {
                const pregunta = seccion.preguntas.find(
                    (seccion) => seccion.preguntadata.id === itemId
                );

                if (pregunta) {
                    pregunta.preguntadata.valor = e.target.value;
                }
            });
        } else if (itemType === 'respuesta') {
            updatedJson.estructura.forEach((seccion) => {
                seccion.preguntas.forEach((pregunta) => {
                    const respuesta = pregunta.respuestas.find(
                        (seccion) => seccion.id === itemId
                    );

                    if (respuesta) {
                        respuesta.valor = e.target.value;
                    }
                });
            });
        }

        setJson(updatedJson);
    };

    function generateUniqueId() {
        return ((Math.floor(Math.random() * 10000)));
    }

    const createNewSection = () => {
        const updatedJson = { ...json };
        const newSection = {
            secciondata: {
                id: 'seccion-' + generateUniqueId(),
                nombre: 'New Section',
                valor: '',
            },
            preguntas: [],
        };
        updatedJson.estructura.push(newSection);
        setJson(updatedJson);
    };

    const deleteSection = (sectionId) => {
        const updatedJson = { ...json };
        const sectionIndex = updatedJson.estructura.findIndex(
            (seccion) => seccion.secciondata.id === sectionId
        );
        if (sectionIndex !== -1) {
            updatedJson.estructura.splice(sectionIndex, 1);
            setJson(updatedJson);
        }
    };

    const createNewQuestion = (sectionId) => {
        const updatedJson = { ...json };
        const sectionIndex = updatedJson.estructura.findIndex(
            (seccion) => seccion.secciondata.id === sectionId
        );
        if (sectionIndex !== -1) {
            const newQuestion = {
                preguntadata: {
                    id: 'pregunta-' + generateUniqueId(),
                    pregunta: 'New Question',
                    valor: '',
                },
                respuestas: [],
            };
            updatedJson.estructura[sectionIndex].preguntas.push(newQuestion);
            setJson(updatedJson);
        }
    };

    const deleteQuestion = (sectionId, questionId) => {
        const updatedJson = { ...json };
        const sectionIndex = updatedJson.estructura.findIndex(
            (seccion) => seccion.secciondata.id === sectionId
        );
        if (sectionIndex !== -1) {
            const questionIndex = updatedJson.estructura[
                sectionIndex
            ].preguntas.findIndex((seccion) => seccion.preguntadata.id === questionId);
            if (questionIndex !== -1) {
                updatedJson.estructura[sectionIndex].preguntas.splice(
                    questionIndex,
                    1
                );
                setJson(updatedJson);
            }
        }
    };

    function minimize() {
        setColapsar(prevState => !prevState);
    }

    return (
        <div className='row mt-3'>
            <div className="datos col">
                {json.datos && (
                    <div>
                        <h2>Edit Datos</h2>
                        <form>
                            <div>
                                <label>ID:</label>
                                <input
                                    type="text"
                                    name="id"
                                    value={json.datos.id}
                                    readOnly
                                />
                            </div>
                            <div>
                                <label>Cliente:</label>
                                <select
                                    name="cliente"
                                    value={json.datos.cliente}
                                    onChange={handleChange}
                                >
                                    <option value={0}>Seleccionar clientes</option>
                                    {clientes.map((client) => (
                                        <option key={client.id} value={client.id}>
                                            {client.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>URL:</label>
                                <input
                                    type="text"
                                    name="url"
                                    value={json.datos.url}
                                    onChange={handleChange}
                                    pattern="[a-zA-Z0-9]+"
                                />
                            </div>
                            <div>
                                <label>Nombre:</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    value={json.datos.nombre}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <input
                                type="hidden"
                                name="metodoscaptura"
                                value={json.datos.metodoscaptura}
                            />
                            <div>
                                <label>Lng:</label>
                                <select
                                    name="lng"
                                    value={json.datos.lng}
                                    onChange={handleChange}
                                >
                                    <option value={1}>Español</option>
                                    <option value={2}>Inglés</option>
                                </select>
                            </div>
                            <div>
                                <label>Lenguaje principal:</label>
                                <select
                                    name="default_lng"
                                    value={json.datos.default_lng}
                                    onChange={handleChange}
                                >
                                    <option value={1}>Español</option>
                                    <option value={2}>Inglés</option>
                                </select>
                            </div>
                        </form>
                    </div>
                )}
                <button
                    className='btn btn-secondary'
                    onClick={() => minimize()}
                >
                    {((!colapsar) ? ('Colapsar') : ('Mostrar'))} secciones
                </button>
            </div>

            {json.estructura && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="encuesta">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="encuesta"
                            >
                                {json.estructura.map((seccion, index) => (
                                    seccion.secciondata ? (
                                        <Draggable
                                            key={seccion.secciondata.id}
                                            draggableId={'seccion' + seccion.secciondata.id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="draggable-section seccion"
                                                >
                                                    <div className='grupoedit'>
                                                        <input
                                                            className='secciontxt'
                                                            type="text"
                                                            value={seccion.secciondata.nombre}
                                                            onChange={(e) =>
                                                                handleSeccionTextChange(
                                                                    e,
                                                                    seccion.secciondata.id
                                                                )
                                                            }
                                                        />
                                                        <input
                                                            className='valor'
                                                            type="text"
                                                            value={seccion.secciondata.valor}
                                                            onChange={(e) =>
                                                                handleValorTextChange(
                                                                    e,
                                                                    seccion.secciondata.id,
                                                                    'seccion'
                                                                )
                                                            }
                                                        />
                                                        <button
                                                            onClick={() => deleteSection(seccion.secciondata.id)}
                                                        >
                                                            Borrar sección
                                                        </button>
                                                    </div>
                                                    <Droppable droppableId={`seccion${seccion.secciondata.id}`} type="pregunta">
                                                        {(provided) => (
                                                            <div
                                                                className={'contpreg ' + ((colapsar) && ('collapsed'))}
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                {seccion.preguntas.map((pregunta, index) => (
                                                                    <Draggable
                                                                        key={pregunta.preguntadata.id}
                                                                        draggableId={'pregunta' + pregunta.preguntadata.id}
                                                                        index={index}
                                                                    >
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className="pregunta"
                                                                            >
                                                                                <div className='grupoedit'>
                                                                                    <input
                                                                                        className='preguntatxt'
                                                                                        type="text"
                                                                                        value={
                                                                                            pregunta.preguntadata.pregunta
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handlePreguntaTextChange(
                                                                                                e,
                                                                                                pregunta.preguntadata.id
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <input
                                                                                        className='valor'
                                                                                        type="text"
                                                                                        value={pregunta.preguntadata.valor}
                                                                                        onChange={(e) =>
                                                                                            handleValorTextChange(
                                                                                                e,
                                                                                                pregunta.preguntadata.id,
                                                                                                'pregunta'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            deleteQuestion(
                                                                                                seccion.secciondata.id,
                                                                                                pregunta.preguntadata.id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Borrar pregunta
                                                                                    </button>
                                                                                    <select
                                                                                        value={pregunta.preguntadata.tipo}
                                                                                        onChange={(e) => handleRespuestaType(e, pregunta.preguntadata.id)}
                                                                                    >
                                                                                        <option value="2">Respuesta múltiple</option>
                                                                                        <option value="3">Texto</option>
                                                                                    </select>
                                                                                </div>
                                                                                {pregunta.preguntadata.tipo === "3" ? (
                                                                                    <span>Respuesta texto</span>
                                                                                ) : (
                                                                                    <Droppable droppableId={`pregunta${pregunta.preguntadata.id}`} type="respuesta">
                                                                                        {(provided) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.droppableProps}
                                                                                            >
                                                                                                {pregunta.respuestas.map((respuesta, index) => (
                                                                                                    <Draggable
                                                                                                        key={respuesta.id}
                                                                                                        draggableId={'respuesta' + respuesta.id}
                                                                                                        index={index}
                                                                                                    >
                                                                                                        {(provided) => (
                                                                                                            <div
                                                                                                                ref={provided.innerRef}
                                                                                                                {...provided.draggableProps}
                                                                                                                {...provided.dragHandleProps}
                                                                                                                className="respuesta"
                                                                                                            >
                                                                                                                <div className='grupoedit'>
                                                                                                                    <input
                                                                                                                        className='respuestatxt'
                                                                                                                        type="text"
                                                                                                                        value={respuesta.texto}
                                                                                                                        onChange={(e) => handleRespuestaTextChange(e, respuesta.id)}
                                                                                                                    />
                                                                                                                    <input
                                                                                                                        className='valor'
                                                                                                                        type="text"
                                                                                                                        value={respuesta.valor}
                                                                                                                        onChange={(e) => handleValorTextChange(e, respuesta.id, 'respuesta')}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <button onClick={() => handleDeleteRespuesta(pregunta.preguntadata.id, respuesta.id)}>Borrar respuesta</button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                ))}
                                                                                                <button onClick={() => handleAddRespuesta(pregunta.preguntadata.id)}>Añadir respuesta</button>
                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Droppable>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                    <button
                                                        onClick={() =>
                                                            createNewQuestion(seccion.secciondata.id)
                                                        }
                                                    >
                                                        Añadir pregunta
                                                    </button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ) : (<Draggable
                                        key={seccion.preguntadata.id}
                                        draggableId={'pregunta' + seccion.preguntadata.id}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="pregunta"
                                            >
                                                <div className='grupoedit'>
                                                    <input
                                                        className='preguntatxt'
                                                        type="text"
                                                        value={seccion.preguntadata.pregunta}
                                                        onChange={(e) =>
                                                            handlePreguntaTextChange(
                                                                e,
                                                                seccion.preguntadata.id
                                                            )
                                                        }
                                                    />
                                                    <input
                                                        className='valor'
                                                        type="text"
                                                        value={seccion.preguntadata.valor}
                                                        onChange={(e) =>
                                                            handleValorTextChange(
                                                                e,
                                                                seccion.preguntadata.id,
                                                                'pregunta'
                                                            )
                                                        }
                                                    />
                                                    <button
                                                        onClick={() =>
                                                            deleteQuestion(
                                                                seccion.preguntadata.id
                                                            )
                                                        }
                                                    >
                                                        Borrar pregunta
                                                    </button>
                                                    <select
                                                        value={seccion.preguntadata.tipo}
                                                        onChange={(e) => handleRespuestaType(e, seccion.preguntadata.id)}
                                                    >
                                                        <option value="2">Respuesta múltiple</option>
                                                        <option value="3">Texto</option>
                                                    </select>
                                                </div>
                                                {seccion.preguntadata.tipo === "3" ? (
                                                    <span>Respuesta texto</span>
                                                ) : (
                                                    <Droppable droppableId={`pregunta${seccion.preguntadata.id}`} type="respuesta">
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                {(seccion.respuestas || []).map((respuesta, index) => (
                                                                    <Draggable
                                                                        key={respuesta.id}
                                                                        draggableId={'respuesta' + respuesta.id}
                                                                        index={index}
                                                                    >
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className="respuesta"
                                                                            >
                                                                                <div className='grupoedit'>
                                                                                    <input
                                                                                        className='respuestatxt'
                                                                                        type="text"
                                                                                        value={respuesta.texto}
                                                                                        onChange={(e) => handleRespuestaTextChange(e, respuesta.id)}
                                                                                    />
                                                                                    <input
                                                                                        className='valor'
                                                                                        type="text"
                                                                                        value={respuesta.valor}
                                                                                        onChange={(e) => handleValorTextChange(e, respuesta.id, 'respuesta')}
                                                                                    />
                                                                                </div>
                                                                                <button onClick={() => handleDeleteRespuesta(seccion.preguntadata.id, respuesta.id)}>Borrar respuesta</button>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                <button onClick={() => handleAddRespuesta(seccion.preguntadata.id)}>Añadir respuesta</button>
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                )}
                                            </div>
                                        )}
                                    </Draggable>)))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
            <button onClick={createNewSection}>Crear Sección</button>
        </div>
    );
};
export default EncuestaEditor;