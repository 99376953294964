import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
/*import { connect } from 'react-redux';
import axios from "axios";*/

const style = {
    flex: 1,
    height: "400px",
    width: "100%"
};

class DashPreguntas extends Component {



    getOption = () => {

        // prettier-ignore
        /*const hours = [
            'Sep', 'Oct', 'Nov', 'Dic', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago'
        ];
        // prettier-ignore
        const days = [
            'Resultados Generales', '¿Cómo calificaría su experiencia en Swarovski?', '¿Qué tan probable es que regrese a comprar a Swarovski?', '¿Qué tan probable es que recomiende comprar en Swarovski?', 'Comparado con sus expectativas, su experiencia en general en Swarovski fue:', '¿Cómo califica el servicio del asesor?'
        ];*/
        const hours = [
            'Sep', 'Oct', 'Nov', 'Dic', 'Ene', 'Mar', 'Abr',
            'May', 'Jun', 'Jul', 'Ago'
        ];
        // prettier-ignore
        const days = [
            '¿Cómo calificaría su experiencia en Swarovski?', '¿Qué tan probable es que regrese a comprar a Swarovski?', '¿Qué tan probable es que recomiende comprar en Swarovski?', 'Comparado con sus expectativas, su experiencia en general en Swarovski fue:', '¿Cómo califica el servicio del asesor?', 'Resultados Generales'
        ];
        // prettier-ignore
        const data = [
            [0, 'Sep', 50], [0, 1, 88], [0, 2, 95], [0, 3, 87], [0, 4, 75], [0, 5, 87], [0, 6, 98], [0, 7, 79], [0, 8, 67], [0, 9, 88], [0, 10, 95], [0, 11, 91], [0, 12, 20],
            [1, 0, 50], [1, 1, 88], [1, 2, 95], [1, 3, 87], [1, 4, 75], [1, 5, 87], [1, 6, 98], [1, 7, 79], [1, 8, 67], [1, 9, 88], [1, 10, 95], [1, 11, 91], [1, 12, 20],
            [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5], [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4], [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0], [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5], [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1], [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4], [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3], [4, 21, 7], [4, 22, 3], [4, 23, 0], [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4], [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3], [5, 21, 4], [5, 22, 2], [5, 23, 0]];
        const title = [];
        const singleAxis = [];
        const series = [];
        days.forEach(function (day, idx) {
            title.push({
                textBaseline: 'middle',
                top: ((idx + 0.1) * 100) / 5 + '%',
                text: day,
                label:{
                    fontSize: 10,
                }
            });
            singleAxis.push({
                left: 50,
                right: 50,
                type: 'category',
                boundaryGap: false,
                data: hours,
                top: (idx * 100) / 5 + 5 + '%',
                height: 100 / 15 + '%',
                axisLabel: {
                    interval: 0,
                    fontSize: 14,
                    fontWeight: 'bold'
                }
            });
            series.push({
                singleAxisIndex: idx,
                coordinateSystem: 'singleAxis',
                type: 'scatter',
                label: {
                    formatter: (params) => {
                        return params.value[1];
                    },
                    position: 'inside',
                    show: true,
                    fontSize: 10,
                    fontWeight: 'bold'
                },
                data: [],
                symbolSize: function (dataItem) {
                    return dataItem[1]*0.5;
                },
            });
        });
        data.forEach(function (dataItem) {
            series[dataItem[0]].data.push([dataItem[1], dataItem[2]]);
        });

        const options = {
            toolbox: {
                show: false,
                itemSize: 30,
                feature: {
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top'
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            title: title,
            singleAxis: singleAxis,
            series: series,
        }
        return options;
    }

    render() {
        return (
            <div id="dashpreguntas">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
/*const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})*/
export default DashPreguntas;