import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
    flex: 1,
    height: "600px",
    width: "100%"
};

class Pantalla0 extends Component {

    constructor(props) {
        super(props);
        this.state = {
          success: false,
          data: false
        };
      }
      peticionAxios() {
        const {clienteid, encuesta, encuestas} = this.props.loggedstate.vars;
        let formData = new FormData();
        formData.append('cliente', clienteid);
        formData.append('encuesta', encuesta);
        axios({
            method: 'post',
          url: process.env.REACT_APP_URL + '0.php',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
          .then(response => response.data)
          .then((data) => {
              this.setState({ data: data[0] });
          })
            .catch(function (response) {
              //handle error
              //console.log(response)
            });
      }

      componentDidMount() {
        this.peticionAxios()
      }

    getOption = () => {

        const preguntas = ['Resultados Generales'];

        Object.keys(this.state.data.preguntas).map((key) => {
            preguntas.push(this.state.data.preguntas[key]);
        });

        const meses = [];
        const data2 = [];

        this.state.data.generales.map(mensual => {
            meses.push(this.props.loggedstate.vars[this.props.loggedstate.vars.idioma].meses[mensual.mes]);
            data2.push([0,this.props.loggedstate.vars[this.props.loggedstate.vars.idioma].meses[mensual.mes],mensual.valor]);
        })

        Object.keys(this.state.data.resultadospreguntas).map((key) => {
            Object.keys(this.state.data.resultadospreguntas[key]).map((key2) => {
                data2.push([key,this.props.loggedstate.vars[this.props.loggedstate.vars.idioma].meses[Number(key2)], this.state.data.resultadospreguntas[key][key2]]);
            });
        });

        //console.log(data2);

        const title = [];
        const singleAxis = [];
        const series = [];
        preguntas.forEach(function (day, idx) {
            title.push({
                textBaseline: 'middle',
                top: ((idx + 0.1) * 100) / 6 + '%',
                text: day
            });
            singleAxis.push({
                left: 50,
                right: 50,
                type: 'category',
                boundaryGap: false,
                data: meses,
                top: (idx * 100) / 6 + 5 + '%',
                height: 100 / 6 - 10 + '%',
                axisLabel: {
                    interval: 0
                }
            });
            series.push({
                singleAxisIndex: idx,
                coordinateSystem: 'singleAxis',
                type: 'scatter',
                label: {
                    formatter: (params) => {
                        return params.value[1]+'%';
                    },
                    position: 'inside',
                    show: true,
                    fontSize: 16,
                    fontWeight: 'bold'
                },
                data: [],
                symbolSize: function (dataItem) {
                    return dataItem[1]*0.8;
                }
            });
        });
        data2.forEach(function (dataItem) {
            series[dataItem[0]].data.push([dataItem[1], dataItem[2]]);
        });

        //console.log(series);
        const options = {
            toolbox: {
                show: true,
                itemSize: 30,
                feature: {
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis',
                position: 'top'
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            title: title,
            singleAxis: singleAxis,
            series: series,
        }
        return options;
    }

    render() {
        if(this.state.data){
            return (
                <div id="pantalla0" className="contenedorgrafica">
                    <ReactEcharts
                        option={this.getOption()}
                        className="grafica"
                        style={style}
                        notMerge={false}
                        lazyUpdate={true}
                    />
                </div>
            );
        }else{
            return (
            <div>Cargando</div>
            )
        }
        
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla0);