import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Pager from './Pager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ModalQR from './ModalQR';

const AdminEncuestasTable = (props) => {
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({ field: 'id', sort: 'desc' });
  const [page, setPage] = useState(1);
  const [totales, setTotales] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [editing, setEditing] = useState(null);
  const [clientes, setClientes] = useState([]); // Assuming you have a list of clientes
  const [selectedClient, setSelectedClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [qrid, setQRid] = useState(null);
  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  useEffect(() => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    axios.get(`${process.env.REACT_APP_API}clientes/getclientes`, {
      cancelToken: source.token
    })
      .then(response => {
        setClientes(response.data.clients);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching user data:', error);
        }
      });
    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  }, []);

  useEffect(() => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    fetchData(page, sort, selectedClient);

    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  }, [page, sort, selectedClient]);

  const fetchData = async (page, sort, clientId) => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}encuestas/getencuestas?pagina=${page}&sort=${sort.field},${sort.sort}&cliente=${clientId || ''}`,
        {
          cancelToken: source.token,
        }
      );

      setData(response.data.encuestas);
      setTotales(response.data.totalPages);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching encuesta data:', error);
      }
    }

    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  };

  const handleclienteselect = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    setPage(1); // Reset to the first page when changing clientes
  };

  const handleEdit = (id) => {
    setEditing(id);
    props.setEditing(id);
  };

  const getQrs = (id) => {
    setQRid(id);
    setShowModal(true);
    /*axios.get(`${process.env.REACT_APP_API}encuestas/getqrs?id=${id}`)
      .then(response => {
        console.log(response.data);
        response.data.forEach(qr => {
          const link = document.createElement('a');
          link.href = qr;
          link.target = '_blank';
          link.click();
        });
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });*/
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Está seguro que desea borrar esta encuesta?')) {
      return;
    }
    if (cancelToken) {
      cancelToken.cancel('Request canceled: New request triggered.');
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}encuestas/deleteencuesta?id=${id}`,
        {
          cancelToken: source.token,
        }
      );
      console.log('Encuesta deleted successfully:', response.data.message);
      fetchData(page, sort, selectedClient);
      toast.info('Encuesta borrada correctamente', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error deleting encuesta:', error);
      }
    }

    return () => {
      source.cancel('Request canceled: Component unmounted or request canceled.');
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div>
            ID
            <button
              className={`btn ${sort.field === 'id' && sort.sort === 'desc' ? 'active' : ''}`}
              onClick={() => setSort({ field: 'id', sort: 'desc' })}
            >
              <ArrowDropUpIcon />
            </button>
            <button
              className={`btn ${sort.field === 'id' && sort.sort === 'asc' ? 'active' : ''}`}
              onClick={() => setSort({ field: 'id', sort: 'asc' })}
            >
              <ArrowDropDownIcon />
            </button>
          </div>
        ),
        accessor: 'id',
      },
      {
        Header: () => (
          <div>
            Nombre
            <button
              className={`btn ${sort.field === 'nombre' && sort.sort === 'desc' ? 'active' : ''}`}
              onClick={() => setSort({ field: 'nombre', sort: 'desc' })}
            >
              <ArrowDropUpIcon />
            </button>
            <button
              className={`btn ${sort.field === 'nombre' && sort.sort === 'asc' ? 'active' : ''}`}
              onClick={() => setSort({ field: 'nombre', sort: 'asc' })}
            >
              <ArrowDropDownIcon />
            </button>
          </div>
        ),
        accessor: 'nombre',
      },
      {
        Header: 'Acciones',
        Cell: ({ row }) => (
          <div>
            <button onClick={() => handleEdit(row.original.id)}>
              <EditNoteIcon />
            </button>
            <button onClick={() => handleDelete(row.original.id)}>
              <DeleteForeverIcon />
            </button>
            {
              row.original.tiendasdrop === 1 &&
              (<button onClick={() => getQrs(row.original.id)}>
                | <QrCodeIcon />
              </button>)
            }

          </div>
        ),
      },
    ],
    [page, sort]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col">
          <div className="filter-container">
            <label htmlFor="clientFilter">Filtrar por Cliente: </label>
            <select
              id="clientFilter"
              value={selectedClient || ''}
              onChange={handleclienteselect}
            >
              <option value="">Todos los Clientes</option>
              {clientes.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.nombre}
                </option>
              ))}
            </select>
          </div>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pager page={page} totales={totales} onPageChange={setPage} />
        </div>
      </div>

      <div>
        <ToastContainer />
        <div className={`modal ${showModal ? 'show' : ''} large-modal`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
            {qrid ? <ModalQR encuestaId={qrid} toggleModal={() => setShowModal(!showModal)} /> : null}
          </div>
        </div>
        {showModal && <div className="modal-backdrop show" onClick={toggleModal}></div>}
      </div>
    </div>
  );
};

export default AdminEncuestasTable;