import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';

const style = {
    flex: 1,
    height: "400px",
    width: "100%"
};

class DashExperiencia extends Component {
    getOption = () => {
        const{preguntas,experiencia,loggedstate} = this.props;
        let labels = [];
        let series = [];
        let iter = 0;

        Object.keys(experiencia).map(function(key, index) {
            series.push({
                name: preguntas[key],
                data: [],
                type: 'line'
            });
            Object.keys(experiencia[key]).map(function(key2, index2) {
                series[index].data.push(experiencia[key][key2]);
                if(iter == 0){
                    labels.push(loggedstate.vars[loggedstate.vars.idioma].meses[Number(key2)]);
                }
            })
            iter += 1;
          });

          //console.log(labels, series);

        const options = {
            tooltip: {
              trigger: 'none',
              axisPointer: {
                type: 'cross'
              }
            },
            legend: {},
            xAxis: {
              type: 'category',
              data: labels
            },
            yAxis: {
              type: 'value'
            },
            series: series
          };
        return options;
    }

    render() {
        return (
            <div id="dashpreguntas">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(DashExperiencia);