import React, { Component } from "react";
import Pager from "../components/Pager";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class Pantalla3 extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            resultados: [],
            totales: 1
        };
    }
    peticionAxios() {
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        const {resultados, page, totales} = this.state;
        let formData = new FormData();
        formData.append('cliente', clienteid);
        formData.append('encuesta', encuesta);
        formData.append('pagina', page);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'pantalla3Enc7.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    resultados: data.resultados,
                    totales: data.totales
                });
            })
            .catch(function (response) {
                //handle error
                //console.log(response)
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.peticionAxios();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.page != prevState.page) {
            this.peticionAxios();
        }
    }

    handleChange = (newPage) => {
        this.setState({page: newPage});
        console.log('New page: ',newPage);
    };

    render() {
        const {resultados, page, totales} = this.state;
        const { clienteid, encuesta, encuestas } = this.props.loggedstate.vars;
        return (
            <div id="pantalla3" className="contenedorgrafica">
                <Table className="Table Table-borderless Table-striped Table-earning">
                    <Thead>
                        <Tr id="cabezal">
                            <Th>Fecha<a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th>Tienda <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th>Ticket <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th>Calificación <a href="1" className="btn"><i className="fas fa-sort-up"></i></a> <a href="2" className="btn"><i className="fas fa-sort-down"></i></a></Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {resultados.map((linea, index) => (
                        <Tr key={index}>
                            <Td>{linea.fecha}</Td>
                            <Td>{linea.sucursal}</Td>
                            <Td>{linea.ticket}</Td>
                            <Td>{linea.calificacion}</Td>
                            <Td><a href={process.env.REACT_APP_URL+"cuestionario.php?id=" + linea.levantamiento + "&encuesta=" + encuesta} target="_blank">Ver</a></Td>
                        </Tr>
                    ))}
                    </Tbody>
                </Table>
                <Pager page={page} totales={totales}  onPageChange={this.handleChange} />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla3);